import React, {useState, useEffect, useCallback, useRef} from 'react'
import {useAsync} from '../utils/use-async'
import SkeletonLoader from '../components/skeleton-loader'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuthClient} from '../utils/use-auth-client'
import {UpgradePackageSelection} from './upgrade-package-selection'
import {Catalog} from './catalog'
import {
  MainContainer,
  WizardContainer,
  StepsContainer,
} from '../components/ui/new-order'
import {UpgradeAddonsSelection} from './upgrade-addons-selection'
import {OnboardingProgram} from './onboarding-program'
import PaymentDetails from './payment-details'
import {Commitment} from './commitment.js'
import {ADD_ON} from '../utils/addons-data'
import {toast} from 'react-toastify'
import {
  COUNTRIES,
  CURRENCYS,
  CC_CURRENCIES,
  CC_PLANS,
  MC_PLANS,
  BC_PLANS,
} from '../utils/constants'
import {useUserRoles} from '../utils/use-user-roles'
import {TSSConfirmation} from './tss-confirmation'
import {CountrySelection} from './country-selection'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'
import {useTrackLR} from '../utils/use-track-lr'
import {ADDONS_TYPES} from '../utils/constants'
import {useFlags} from 'launchdarkly-react-client-sdk'
import useAutoCoupons from '../utils/use-auto-coupons'
import {getUpgradeIncentivePlans} from '../utils/incentives'
import useDebounce from '../utils/useDebounce'
import {InvoiceInformation} from '../components/account-information-components/invoice-information'
import {NewOrderLayout} from '../components/new-order-layout.js'

const TOTAL_STEPS = 5

const isObjectEmpty = obj => Object.keys(obj).length === 0

const getPlanCode = (currentPlan, selectedPlan, billingPreference) => {
  if (selectedPlan === 'marketingCenter' || selectedPlan === 'thryvLeads') {
    return currentPlan.planCode
  }
  return `${selectedPlan}-4-dexyp-${
    billingPreference === 'monthly' ? 'm2m' : 'semi'
  }`
}

function Container({children, frontendSideNav}) {
  if (frontendSideNav) {
    return <div className="d-flex">{children}</div>
  }
  return children
}

function UpgradeOrder({selectedCountry}) {
  const navigate = useNavigate()
  const {state, pathname} = useLocation()
  console.log('order - on init upgrade order', state)
  const [order, setOrder] = useState(state?.order || {})
  const [orderData5CU, setOrderData5CU] = useState({})
  const [orderData4CU, setOrderData4CU] = useState({})
  const [order5NUId, setOrder5NUId] = useState(null)
  // const [order4NUId, setOrder4NUId] = useState(null)
  const [order5SelectedLines, setOrder5SelectedLines] = useState({})
  const [order4SelectedLines, setOrder4SelectedLines] = useState({})
  const [order5LinesDiscounts, setOrder5LinesDiscounts] = useState([])
  const [order4LinesDiscounts, setOrder4LinesDiscounts] = useState([])
  const [order5OnboardingApptId, setOrder5OnboardingApptId] = useState(null)
  const [order5CreativeApptId, setOrder5CreativeApptId] = useState(null)

  const [accountInfo, setAccountInfo] = useState({})
  const [accountSubscriptionData, setAccountSubscriptionData] = useState({})
  const [currentStep, setCurrentStep] = useState(1)
  const [allSelectedPurchaseIntent, setAllSelectedPurchaseIntent] = useState({
    firstPurchaseIntent: {},
    secondPurchaseIntent: {},
    thirdPurchaseIntent: {},
    csmExtraInfo: '',
  })
  const [onboardingCallDate, setOnboardingCallDate] = useState(null)
  const [creativeCallDate, setCreativeCallDate] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [isLoading, setIsLoading] = useState(order.v5upgrade ? false : true)
  const [kickoffApptId, setKickoffApptId] = useState(null)
  const [creativeApptId, setCreativeApptId] = useState(null)
  const [orderCouponCodes, setOrderCouponCodes] = useState([])
  const [cartProducts, setCartProducts] = useState([])
  const [clientInitials, setClientInitials] = useState()
  const [ccCouponInfo, setCcCouponInfo] = useState()
  const [incentiveCoupon, setIncentiveCoupon] = useState(false)
  const [ccUpgradeCoupons, setCcUpgradeCoupons] = useState(null)
  const [upgradePaymentPreview, setUpgradePaymentPreview] = useState(null)
  const [previewRequestFinished, setPreviewRequestFinished] = useState(false)
  const [all5PlanPrices, setAll5PlanPrices] = useState(null)
  const [isInvoiceBilled, setIsInvoiceBilled] = useState(false)
  const [isEaidFound, setIsEaidFound] = useState(false)
  const [cohortEaid, setCohortEaid] = useState(false)
  const [allowInvoice, setAllowInvoice] = useState(false)
  const [isCohort, setIsCohort] = useState(false)
  const [showSmsOption, setShwoSmsOption] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('Credit Card')
  const defaultCentersAndApps = useRef()
  const expiredCentersAndApps = useRef()
  const previewToastShown = useRef(false)
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })
  //const STARTER_PACKAGE_ADDON_CODES = ['addon-5-mcstartgr', 'addon-5-mcexpangr']
  const {roles} = useUserRoles()
  const {frontendPreviewPayment, frontendSideNav, frontendListingsBcMc} =
    useFlags()
  const customEvent = {
    key: 'upgrade-order',
    ...(accountInfo.recurlyEmail && {companyEmail: accountInfo.recurlyEmail}),
    ...(accountInfo.recurlyCompany && {
      companyName: accountInfo.recurlyCompany,
    }),
    ...(accountInfo.thryvID && {thryvId: accountInfo.thryvID}),
  }
  useTrackLR(customEvent)
  const isCCPlan = accountInfo?.planCode?.includes('-5-') || order?.v5upgrade
  const selectedPlanCode =
    !isCCPlan &&
    `${order.selectedPlan?.name}-4-dexyp-${
      order.billingPreference?.value === 'monthly' ? 'm2m' : 'semi'
    }`
  const isUpgrade = pathname === '/order/upgrade'
  const eligibleTransitionCenters =
    isUpgrade &&
    order.selectedCentersAndApps &&
    getUpgradeIncentivePlans(
      order.selectedCentersAndApps?.centers,
      defaultCentersAndApps.current?.centers,
      upgradePaymentPreview,
    )
  const {autoCouponInfo, resetAutoCoupons} = useAutoCoupons({
    selectedPackage: selectedPlanCode,
    selectedCountry: COUNTRIES[accountInfo.billingAddress?.country],
    selectedCentersAndApps: order.selectedCentersAndApps,
    defaultCentersAndApps: defaultCentersAndApps,
    expiredCentersAndApps: expiredCentersAndApps,
    eligibleTransitionCenters,
    selectedAddons: order.selectedAddons,
    upgradeAccountInfo: accountInfo,
    incentiveCoupon,
    setIncentiveCoupon,
    currentStep,
    setOrderCouponCodes,
    ccCouponInfo,
    upgradePaymentPreview,
    isEaidFound,
    isCohort,
  })
  const hasWebsiteAddon = addons => addons?.some(({name}) => name === 'Website')

  const isWebsiteIncluded =
    hasWebsiteAddon(accountInfo?.addOns) ||
    hasWebsiteAddon(accountInfo?.legacyAddOns)
  const isSocialBoostIncluded = accountInfo?.addOns?.some(
    ({name}) => name === ADD_ON.entitlements['Social Boost'].label,
  )
  const isSEOIncluded = order?.selectedCentersAndApps?.centers?.some(
    ({name, alreadyIncluded}) => name.includes('SEO') && !alreadyIncluded,
  )

  const getGmailItems = () => {
    let result = []
    const gmailStandard = accountInfo?.addOns?.find(
      ({name}) => name === 'Gmail Standard',
    )
    const gmailStandardFree = accountInfo?.addOns?.find(
      ({name}) => name === 'Gmail Standard Free',
    )
    const gmailCertified = accountInfo?.addOns?.find(
      ({name}) => name === 'Gmail Certified',
    )
    if (gmailStandard) {
      result.push(gmailStandard)
    }
    if (gmailStandardFree) {
      result.push(gmailStandardFree)
    }
    if (gmailCertified) {
      result.push(gmailCertified)
    }
    return result
  }

  console.log('user roles', roles)
  const isTSS = getAuthByRole(['tss'], roles)
  const isAus = selectedCountry === 'AU'
  const isNZ = selectedCountry === 'NZ'
  const isClientCare = getAuthByRole(['clientcare'], roles)
  const isReseller = getAuthByRole(['reseller'], roles)

  useEffect(() => {
    const get5PlanPrices = async () => {
      const url = 'plans/5/list'
      const response = await client(url)
      setAll5PlanPrices(response)
    }

    get5PlanPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (kickoffApptId) {
      return () => {
        client(`plans/onboarding`, {
          data: {
            type: 'onboarding',
            country: accountInfo?.billingAddress?.country,
            demoApptId: kickoffApptId,
          },
          method: 'DELETE',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kickoffApptId])

  useEffect(() => {
    if (creativeApptId) {
      return () => {
        client(`plans/creative`, {
          data: {
            type: 'creative',
            country: accountInfo?.billingAddress?.country,
            demoApptId: creativeApptId,
          },
          method: 'DELETE',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeApptId])

  const nextStep = data => {
    console.log('next step - upgrade order', data)
    console.log(
      'next step - upgrade order - accountSubscriptionData',
      accountSubscriptionData,
    )
    if (data?.selectedCentersAndApps?.oneTimes) {
      let updatedOneTimesData = data.selectedCentersAndApps.oneTimes.filter(
        item => {
          if (
            item.code === 'thryvprfee' &&
            (accountInfo.hasPaidOnboardingFee ||
              accountSubscriptionData.accountInfo.hasPaidOnboardingFee)
          ) {
            return false
          }
          if (
            item.code === 'thryvdesg2' &&
            (accountInfo.hasPaidDesignFee ||
              accountSubscriptionData.accountInfo.hasPaidDesignFee)
          ) {
            return false
          }
          return true
        },
      )
      data.selectedCentersAndApps.oneTimes = updatedOneTimesData
    }
    setOrder({...order, ...data})
    let _currentStep = currentStep
    if (currentStep >= TOTAL_STEPS - 1) {
      _currentStep = TOTAL_STEPS
    } else {
      _currentStep = currentStep + 1
    }
    setCurrentStep(_currentStep)
  }

  const prevStep = () => {
    let _currentStep = currentStep
    if (currentStep <= 1) {
      _currentStep = 1
      navigate(!isReseller ? '/all-accounts' : '/my-accounts')
    } else {
      _currentStep = currentStep - 1
    }
    setCurrentStep(_currentStep)
  }

  const updateInitials = initials => {
    setClientInitials(initials)
  }

  const fetchAccountInfo = useCallback(async () => {
    if (order?.v5upgrade) return
    try {
      let url = `subscription/uuid-${order.billingAccountId}`
      const response = await client(url)
      response.thryvID = response.thryvID || order.thryvId
      const emailValidation = await client(
        `orders/validate-email/${response.recurlyEmail}`,
      )
      if (emailValidation.isInvoiceBilled) setIsInvoiceBilled(true)
      if (isCCPlan) {
        setOrder({
          ...order,
          CCAccountInfo: {thryvId: response.thryvID},
          ...(!order.template && {template: response.industry}),
        })
      }
      setAccountInfo(response)
      setOrderData4CU({
        order: order,
        accountInfo: response,
      })
      console.log('orderData4CU - fetchAccountInfo', {
        order: order,
        accountInfo: response,
      })
      if (!response?.billingAddress?.country) {
        setCurrentStep(0)
      }
      setIsLoading(false)
    } catch (err) {
      if (err.status === 404) {
        toast.error(err.message)
        navigate(-1)
      }
      if (err.status !== 404) throw err
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, order.billingAccountId, order.thryvId, isCCPlan])

  const sendPaymentLink = async (contactMethod, contactInfo) => {
    const intents = !order.onBoardingProgramData.intents.every(isObjectEmpty)
      ? order.onBoardingProgramData.intents.map(intent => intent.option)
      : []
    const altPlanCodes = [
      ...ADD_ON.add_ons['Marketing Center Plus'].addOnCodes,
      ...ADD_ON.add_ons['Thryv Leads'].addOnCodes,
    ]
    const addonPlanCodes = order.selectedAddons
      .filter(({product}) => product !== 'One Time Design Fee')
      .filter(({planCode}) => planCode)
      .filter(({planCode}) => !planCode.includes('dexyp'))
      .filter(({planCode}) => !planCode.includes('-setupfee'))
      .filter(
        ({type, planCode}) =>
          !(
            type === ADDONS_TYPES['SELECTED_PLAN'] &&
            altPlanCodes.includes(planCode)
          ),
      )
      .map(({planCode, quantity = 1}) => ({
        addonPlanCode: planCode,
        quantity,
      }))

    const websiteQty =
      order.selectedAddons.find(
        addon =>
          addon.product === ADD_ON.one_time['5 Extra Website Pages'].label,
      )?.quantity || 0
    const thryvLeadsId = order.selectedAddons.find(addon => addon.thryvLeadsId)
    const thryvLeadBillingDate = order.selectedAddons.find(
      addon => addon.thryvLeadBillingDate,
    )
    const seoProposalId = order.selectedAddons.find(
      addon => addon.seoProposalId,
    )
    const seobase4Addon = order.selectedAddons.find(
      addon => addon.planCode === 'addon-4-seobase-m2m',
    )
    const seoBillingDate = order.selectedAddons.find(
      addon => addon.seoBillingDate,
    )
    const mcAddon = order.selectedAddons.find(
      x => x.product === ADD_ON.add_ons['Marketing Center Plus'].label,
    )
    const webSiteDomain = order.selectedAddons.find(
      addon => addon.sensisSiteDomain,
    )
    const subchannel = order.subchannel
    const leadId = order.leadId
    const selectedPlanCode = getPlanCode(
      {planCode: accountInfo.planCode, planName: accountInfo.planName},
      order.selectedPlan.name,
      order.billingPreference.value,
    )

    const data = {
      firstName: accountInfo.recurlyFirst,
      lastName: accountInfo.recurlyLast,
      email: accountInfo.recurlyEmail,
      package: selectedPlanCode,
      company: accountInfo.recurlyCompany,
      postCode: accountInfo.billingAddress.postalCode,
      address: accountInfo.recurlyStreet,
      city: accountInfo.billingAddress.city,
      state: accountInfo.billingAddress.region,
      country: accountInfo.billingAddress.country,
      curr: CURRENCYS[COUNTRIES[accountInfo.billingAddress.country].value],
      mobile: accountInfo.recurlyPhone,
      companyPhone: accountInfo.billingAddress.phone,
      software: order.selectedPlan.industryType,
      purchasePlanCode: selectedPlanCode,
      addonPlanCodes,
      intents,
      contact: {
        sendLinkVia: isTSS || isClientCare ? 'none' : contactMethod,
      },
      isSync: accountInfo?.product?.toLowerCase() === 'thryv' ? false : true,
      planType: order.billingPreference.value === 'monthly' ? 'm2m' : 'semi',
      currentPlanCode: accountInfo.planCode,
      thryvId: accountInfo.thryvID,
      recurlyAccountId: accountInfo.recurlyAccountId,
      orderNotes: order.onBoardingProgramData.csmExtraInfo,
    }
    if (accountInfo?.industry) {
      data.industryId = accountInfo.industry
    }
    if (thryvLeadsId) {
      data.thryvLeadsId = thryvLeadsId.thryvLeadsId
    }
    if (thryvLeadBillingDate) {
      data.thryvLeadsDate = thryvLeadBillingDate.thryvLeadBillingDate
    }
    if (seoProposalId) {
      data.seoProposalId = seoProposalId.seoProposalId
    }
    if (seoProposalId && seoProposalId.seoDomain) {
      data.seoDomain = seoProposalId.seoDomain
    }
    if (seoBillingDate) {
      data.seoDate = seoBillingDate.seoBillingDate
    }
    if (mcAddon) {
      data.sensisSiteDomain = mcAddon.sensisSiteDomain
    } else if (webSiteDomain) {
      data.sensisSiteDomain = webSiteDomain.sensisSiteDomain
    }
    if (subchannel) {
      data.subchannel = subchannel
    }
    if (leadId) {
      data.leadId = leadId
    }
    if (websiteQty > 0) {
      data.websiteQty = websiteQty
    }
    if (kickoffApptId) {
      data.onboardingApptId = kickoffApptId
    }
    if (creativeApptId) {
      data.creativeApptId = creativeApptId
    }
    if (order?.xpCode) {
      data.xpCode = order.xpCode
    }
    if (order?.differentSalesRep?.value) {
      data.behalf = order.salesRepCode
      if (order.behalfFirst) {
        data.behalfFirst = order.behalfFirst
      }
      if (order.behalfLast) {
        data.behalfLast = order.behalfLast
      }
      if (order.behalfEmail) {
        data.behalfEmail = order.behalfEmail
      }
    }
    if (order?.overrideRep?.value) {
      data.overrideRepId = order.overrideRepCode
      if (order.overrideRepFirst) {
        data.overrideRepFirstName = order.overrideRepFirst
      }
      if (order.overrideRepLast) {
        data.overrideRepLastName = order.overrideRepLast
      }
      if (order.overrideRepEmail) {
        data.overrideRepEmail = order.overrideRepEmail
      }
    }
    if (order.clientId) {
      data.enterpriseId = order.clientId
    }
    if (contactMethod === 'sms') {
      data.contact.mobile = contactInfo
    } else if (contactMethod === 'email') {
      data.contact.email = contactInfo
    }
    const COUPON_CODES = orderCouponCodes.map(coupon => {
      return coupon.code
    })
    if (COUPON_CODES.length > 0) {
      data.couponCodes = COUPON_CODES
    }
    if ('sensisSiteDomain' in data && !data.sensisSiteDomain) {
      delete data.sensisSiteDomain
    }
    if (selectedPaymentMethod) {
      data.isInvoiceBilled = selectedPaymentMethod === 'Invoice'
    }
    if (cohortEaid) {
      data.cohortEaid = cohortEaid
    }
    if (allowInvoice && selectedPaymentMethod !== 'Invoice') {
      data.updateToAutopay = true
    }
    if (seobase4Addon) {
      data.seoDomain = seobase4Addon.domain || ''
      data.seoProposalId = seobase4Addon.proposalId || null
    }
    try {
      const result = await run(
        client('orders/process/upgrade/send-link', {data}),
      )
      if (result.message === 'sms sent' || result.message === 'email sent') {
        toast.success(result.message)
      } else {
        toast.error(result.message)
      }
      if (result.shortenedUrl) {
        updateOrder5Status('send-link', 'Submitted', result)
      }
      if (result.randomId) {
        return result
      }
    } catch (error) {
      setProcessing(false)
      if (error.status !== 400) {
        throw error
      }
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        toast.error(error.message)
      }
    }
  }

  const findWebsiteEntitlement = centers =>
    centers
      .find(({entitlements}) =>
        entitlements.some(
          ({name}) => name === 'Professionally Designed Website',
        ),
      )
      ?.entitlements.find(
        ({name}) => name === 'Professionally Designed Website',
      )

  const findWebsiteApp = ({name}) => name === 'Professionally Designed Website'

  const formatCenters = (centers, callbackFn) =>
    centers
      .filter(callbackFn)
      .map(({code, quantity = 1, entitlements, startDate}) => ({
        code,
        quantity,
        ...(startDate && {startDate}),
        addons: entitlements.map(({code, quantity = 1}) => ({code, quantity})),
      }))

  const sendCCPaymentLink = async (contactMethod, contactInfo) => {
    const {centers, apps, oneTimes} = order.selectedCentersAndApps
    const thryvLeadsId = centers.find(
      ({name}) => name === 'Thryv Leads',
    )?.thryvLeadsId
    const seoProposal = centers.find(
      ({name}) => name.includes('SEO') && name !== 'SEO',
    )
    const alphaSeo = centers.find(({name}) => name === 'SEO')
    const sensisDomain = (
      findWebsiteEntitlement(centers) || apps.find(findWebsiteApp)
    )?.sensisSiteDomain
    const mcDomain = centers.find(({name}) =>
      name.toLowerCase().includes('marketing'),
    )?.mcDomain
    const parentSubscriptions = formatCenters(centers, ({name}) =>
      name.includes('Command Center'),
    )
    const marketplace = apps.find(({name}) => name === 'Thryv Marketplace')
    const isBCorMCInCart = centers.some(
      ({code}) => MC_PLANS.includes(code) || BC_PLANS.includes(code),
    )
    let childSubscriptions = []
    for (let center of centers) {
      if (center?.name.includes('Command Center')) {
        continue
      } else {
        const formattedCenter = formatCenters(centers, ({name}) =>
          name.includes(center?.name),
        )
        childSubscriptions.push(formattedCenter[0])
      }
    }

    childSubscriptions.push({
      code: marketplace.code,
      quantity: 1,
      addons: apps
        .filter(({name, code}) => {
          const isListingsApp = code === 'app-listings'
          if (isListingsApp && isBCorMCInCart && frontendListingsBcMc)
            return false
          return (
            name !== 'Thryv Marketplace' &&
            (name !== 'SEO Keywords') & (name !== 'SEO PowerBoost')
          )
        })
        .map(({code, quantity = 1}) => {
          return {
            code,
            quantity,
          }
        }),
    })
    const oneTimeFees = oneTimes.map(({code, quantity = 1}) => {
      return {
        code,
        quantity,
      }
    })

    // Hanlde new Alpha SEO
    const newAlphaSEO = centers.find(({name}) => name === 'SEO')
    const keyword = apps.find(({name}) => name === 'SEO Keywords')
    const powerboost = apps.find(({name}) => name === 'SEO PowerBoost')
    if (newAlphaSEO && keyword && powerboost) {
      for (let subscription of childSubscriptions) {
        if (subscription.code === 'seobase-5-thryv-m2m') {
          if (keyword)
            subscription.addons.push({
              code: keyword.code,
              quantity: keyword.quantity,
            })
          if (powerboost)
            subscription.addons.push({
              code: powerboost.code,
              quantity: powerboost.quantity,
            })
        }
      }
    }

    const data = {
      firstName: accountInfo.recurlyFirst,
      lastName: accountInfo.recurlyLast,
      email: accountInfo.recurlyEmail,
      company: accountInfo.recurlyCompany,
      currency:
        CC_CURRENCIES[COUNTRIES[accountInfo.billingAddress.country].value],
      industryId: order.template || accountInfo.industry,
      businessAddress: {
        street: accountInfo.billingAddress.street1,
        city: accountInfo.billingAddress.city,
        state: accountInfo.billingAddress.state,
        postalCode:
          accountInfo.billingAddress.postalCode ||
          accountInfo.billingAddress.zipCode,
        country: accountInfo.billingAddress.country,
        phone: accountInfo.billingAddress.phone,
      },
      contactPhone: contactInfo,
      thryvId: order.CCAccountInfo.thryvId || accountInfo.thryvID,
      flow: 'upgrade',
      sendLinkVia: contactMethod,
      parentSubscriptions,
      childSubscriptions,
      oneTimeFees,
      coupons: ccUpgradeCoupons || [],
      isCohort,
    }
    if (cohortEaid) {
      data.cohortEaid = cohortEaid
    }
    if (accountInfo?.salesRepCode) {
      data.behalfRepId = accountInfo.salesRepCode
      if (accountInfo.behalfFirst) {
        data.behalfFirstName = accountInfo.behalfFirst
      }
      if (accountInfo.behalfLast) {
        data.behalfLastName = accountInfo.behalfLast
      }
      if (accountInfo.behalfEmail) {
        data.behalfEmail = accountInfo.behalfEmail
      }
    }
    if (accountInfo?.overrideRepCode) {
      data.overrideRepId = accountInfo.overrideRepCode
      if (accountInfo.overrideRepFirst) {
        data.overrideRepFirst = accountInfo.overrideRepFirst
      }
      if (accountInfo.overrideRepLast) {
        data.overrideRepLast = accountInfo.overrideRepLast
      }
      if (accountInfo.overrideRepEmail) {
        data.overrideRepEmail = accountInfo.overrideRepEmail
      }
    }
    if (accountInfo?.xpCode) {
      data.xpCode = accountInfo.xpCode
    }
    if (['AU', 'NZ'].includes(accountInfo.billingAddress.country)) {
      data.abn = accountInfo.abn.replaceAll(' ', '')
      if (!data.abn.length) delete data.abn
    }
    if (kickoffApptId) {
      data.onboardingApptId = kickoffApptId
    }
    if (creativeApptId) {
      data.creativeApptId = creativeApptId
    }
    if (thryvLeadsId) {
      data.thryvLeadsId = thryvLeadsId
    }
    if (seoProposal) {
      data.seoProposalId = seoProposal.seoProposalId
      data.seoDomain = seoProposal.seoDomain
    }
    if (alphaSeo) {
      data.seoProposalId = alphaSeo.alphaProposalId
      data.seoDomain = alphaSeo.alphaDomain
      // data.seoCategories = alphaSeo.alphaCategories
      // data.seoGeos = alphaSeo.alphaGeos
    }
    if (sensisDomain) {
      data.sensisDomain = sensisDomain
    }
    if (mcDomain) {
      data.mcDomain = mcDomain
    }
    if (contactMethod === 'tss') {
      data.tssUpgrade = true
    }
    if (selectedPaymentMethod) {
      data.isInvoiceBilled = selectedPaymentMethod === 'Invoice'
    }
    if (allowInvoice && selectedPaymentMethod !== 'Invoice') {
      data.updateToAutopay = true
    }

    try {
      const result = await run(client('cc/order/send-link', {data}))
      if (
        result.message === 'Payment link sent' ||
        result.message === 'Order created'
      ) {
        toast.success(result.message)
        updateOrder5Status('send-link', 'Submitted', result)
      } else {
        toast.error(result.message)
      }
      if (result.paymentUuid) {
        return result
      }
    } catch (error) {
      setProcessing(false)
      if (error.status !== 400) {
        toast.error(`Error sending link: ${error.message}`)
        throw error
      }
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        toast.error(`Error sending link: ${error.message}`)
      }
    }
  }

  const resendPaymentLink = async current => {
    try {
      const data = {
        orderId: current.orderId.toString(),
        country: COUNTRIES[accountInfo.billingAddress.country].isoValue,
        method: current.method,
        email: current.email,
      }
      if (isCCPlan) {
        data.contactPhone = current.mobile
      } else {
        data.mobile = current.mobile
      }
      const {newUuid, shortenedUrl} = await run(
        client(
          !isCCPlan ? 'orders/process/resend-link' : 'cc/order/resend-link',
          {data, method: 'PATCH'},
        ),
      )
      if (newUuid) {
        toast.success('Payment Link resent ')
        return {updatedUuid: newUuid, shortenedUrl}
      } else {
        toast.error('Error resending link')
      }
    } catch (error) {
      toast.error('Error resending link')
      throw error
    }
  }

  const checkClientEmailStatus = async current => {
    const data = {
      email: current.email,
    }

    try {
      const result = await run(
        client(`orders/email-status/${data.email}`, {method: 'GET'}),
      )

      if (result.haswarningorError) {
        toast.error(result.error_message)
      }
      if (
        result.haswarningorError === false &&
        result.show_error_msg === false
      ) {
        if (result.show_send_sms_btn) {
          setShwoSmsOption(true)
        }
      } else {
        setShwoSmsOption(false)
      }
    } catch (err) {
      toast.error(err.error)
    }
  }

  const updateAddressInfo = (data, setShowUpdateAddressModal) => {
    let saveData = {
      ...accountInfo,
      recurlyFirst: data.firstName,
      recurlyLast: data.lastName,
      recurlyCompany: data.company,
      salesRepCode: data.salesRepCode,
      salesRepInfo: data.salesRepInfo,
      behalfEmail: data.behalfEmail,
      behalfFirst: data.behalfFirst,
      behalfLast: data.behalfLast,
      xpCode: data.xpCode,
      overrideRepInfo: data.overrideRepInfo,
      overrideRepCode: data.overrideRepCode,
      overrideRepEmail: data.overrideRepEmail,
      overrideRepFirst: data.overrideRepFirst,
      overrideRepLast: data.overrideRepLast,
      abn: data.abn,
      billingAddress: {
        ...accountInfo.billingAddress,
        street1: data.street1,
        city: data.city,
        state: data.region,
        zipCode: data.postalCode,
        phone: data.phone,
        mobile: data.mobile,
      },
    }
    if (!isAus && !isNZ) {
      delete saveData.abn
    }
    if (!data.salesRepCode) {
      delete saveData.salesRepInfo
      delete saveData.salesRepCode
      delete saveData.behalfEmail
      delete saveData.behalfFirst
      delete saveData.behalfLast
    }
    if (!data.overrideRepCode) {
      delete saveData.overrideRepInfo
      delete saveData.overrideRepCode
      delete saveData.overrideRepEmail
      delete saveData.overrideRepFirst
      delete saveData.overrideRepLast
    }
    setAccountInfo(saveData)
    setOrderData5CU({
      ...orderData5CU,
      accountInfo: {...saveData},
    })
  }

  const updateBillingAddress = data => {
    if (data.selectedCountry) {
      let _currentStep = currentStep
      if (_currentStep === 0) {
        _currentStep = 1
      }
      setAccountInfo({
        ...accountInfo,
        billingAddress: {
          ...accountInfo.billingAddress,
          country: data.selectedCountry.isoValue,
        },
      })
      setOrderData4CU({
        ...orderData4CU,
        accountInfo: {
          ...accountInfo,
          billingAddress: {
            ...accountInfo.billingAddress,
            country: data.selectedCountry.isoValue,
          },
        },
      })
      console.log('setOrderData4CU', {
        ...orderData4CU,
        accountInfo: {
          ...accountInfo,
          billingAddress: {
            ...accountInfo.billingAddress,
            country: data.selectedCountry.isoValue,
          },
        },
      })
      setCurrentStep(_currentStep)
      return
    }

    const newAccountInfo = {
      ...accountInfo,
      billingAddress: {
        ...accountInfo.billingAddress,
        street1: data.streetAddress || data.street1,
        city: data.city,
        region: data.state || data.region,
        postalCode: data.zipCode || data.postalCode,
      },
    }

    if (data.streetAddress || data.street1) {
      newAccountInfo.recurlyStreet = data.streetAddress || data.street1
    }
    if (data.phone) {
      newAccountInfo.billingAddress.phone = data.phone
    }
    if (data.company) {
      newAccountInfo.recurlyCompany = data.company
    }
    if (data.firstName) {
      newAccountInfo.recurlyFirst = data.firstName
    }
    if (data.lastName) {
      newAccountInfo.recurlyLast = data.lastName
    }
    if (data.mobile) {
      newAccountInfo.recurlyPhone = data.mobile
    }
    setAccountInfo(newAccountInfo)
    setOrderData4CU({
      ...orderData4CU,
      // accountInfo: {updatedAddressInfo: data, ...newAccountInfo},
      accountInfo: {...newAccountInfo},
    })
    console.log('setOrderData4CU', {
      ...orderData4CU,
      accountInfo: {...newAccountInfo},
    })
  }

  const getPaymentPreview = async () => {
    const {
      selectedCentersAndApps: {apps, centers, oneTimes},
    } = order
    const isMCInCart = centers.some(({code}) => MC_PLANS.includes(code))
    const isBCInCart = centers.some(({code}) => BC_PLANS.includes(code))
    const parentSubscriptions = formatCenters(centers, ({name}) =>
      name.includes('Command Center'),
    )
    const marketplace = apps.find(({name}) => name === 'Thryv Marketplace')
    let childSubscriptions = []
    for (let center of centers) {
      if (center?.name.includes('Command Center')) {
        continue
      } else {
        const formattedCenter = formatCenters(centers, ({name}) =>
          name.includes(center?.name),
        )
        childSubscriptions.push(formattedCenter[0])
      }
    }

    childSubscriptions.push({
      code: marketplace.code,
      quantity: 1,
      addons: apps
        .filter(({name, code}) => {
          const isListingsApp = code === 'app-listings'
          if (
            isListingsApp &&
            (isMCInCart || isBCInCart) &&
            frontendListingsBcMc
          )
            return false
          return (
            name !== 'Thryv Marketplace' &&
            (name !== 'SEO Keywords') & (name !== 'SEO PowerBoost')
          )
        })
        .map(({code, quantity = 1}) => {
          return {
            code,
            quantity,
          }
        }),
    })
    const oneTimeFees = oneTimes.map(({code, quantity = 1}) => {
      return {
        code,
        quantity,
      }
    })

    // Hanlde new Alpha SEO
    const newAlphaSEO = centers.find(({name}) => name === 'SEO')
    const keyword = apps.find(({name}) => name === 'SEO Keywords')
    const powerboost = apps.find(({name}) => name === 'SEO PowerBoost')
    if (newAlphaSEO && (keyword || powerboost)) {
      for (let subscription of childSubscriptions) {
        if (subscription.code === 'seobase-5-thryv-m2m') {
          if (keyword)
            subscription.addons.push({
              code: keyword.code,
              quantity: keyword.quantity,
            })
          if (powerboost)
            subscription.addons.push({
              code: powerboost.code,
              quantity: powerboost.quantity,
            })
        }
      }
    }

    const currency =
      CC_CURRENCIES[COUNTRIES[accountInfo.billingAddress.country].value]
    const data = {
      childSubscriptions,
      parentSubscriptions,
      oneTimeFees,
      thryvId: accountInfo.thryvID || order.CCAccountInfo.thryvId,
      currency,
      coupons: ccUpgradeCoupons,
    }
    if (!previewToastShown.current) {
      try {
        const response = await client('cc/order/preview-payment', {data})
        setUpgradePaymentPreview(response)
        if (ccUpgradeCoupons) {
          const isTransitionIncentiveCoupon = ccUpgradeCoupons.some(x =>
            x.includes('25month1', '25month2', '25month3'),
          )
          response['isTransitionIncentiveCoupon'] = isTransitionIncentiveCoupon
          setUpgradePaymentPreview(response)
        }
        setPreviewRequestFinished(true)
      } catch (err) {
        if (err.message === 'Billing info missing on account') {
          toast.warning(
            'Payment Preview Unavailable: Billing info missing on account',
          )
          previewToastShown.current = true
          setUpgradePaymentPreview(null)
          setPreviewRequestFinished(true)
        }
      }
    }
  }

  const captureOrder5NewUpgrade = async (context, orderFlow, contextData) => {
    try {
      console.log('captureOrder5NewUpgrade() - context', context)
      console.log('captureOrder5NewUpgrade() - orderFlow', orderFlow)
      console.log('captureOrder5NewUpgrade() - contextData', contextData)
      console.log('captureOrder5NewUpgrade() - orderData5CU', orderData5CU)
      console.log(
        'captureOrder5NewUpgrade() - ccUpgradeCoupons',
        ccUpgradeCoupons,
      )

      const data = {
        order_status: 'Pending',
        order_version: '5.0',
        order_flow: 'Update',
        order_submitted_date: new Date(),
        order_submitted_by: 'null',
        order_completed_date: null,
        industry_id: orderData5CU.order.industryCategoryId.toString(),
        industry_description: orderData5CU.order.industryCategoryDescription,
        country_code: orderData5CU.order.selectedCountry.isoValue,
        account_email: orderData5CU.accountInfo.email,
        contact_first_name: orderData5CU.accountInfo.firstName,
        contact_last_name: orderData5CU.accountInfo.lastName,
        contact_phone: orderData5CU.accountInfo.businessAddress.phone
          ? orderData5CU.accountInfo.businessAddress.phone.toString()
          : orderData5CU.accountInfo.billingAddress.phone.toString(), // check
        contact_mobile: orderData5CU.accountInfo.businessAddress.phone
          ? orderData5CU.accountInfo.businessAddress.phone.toString()
          : orderData5CU.accountInfo.billingAddress.phone.toString(), // check
        contact_email: orderData5CU.accountInfo.email,
        company_name: orderData5CU.accountInfo.company,
        company_address1: orderData5CU.accountInfo.businessAddress.street1,
        company_address2: orderData5CU.accountInfo.businessAddress.street2,
        company_city: orderData5CU.accountInfo.businessAddress.city,
        company_state: orderData5CU.accountInfo.businessAddress.region,
        company_zipcode: orderData5CU.accountInfo.businessAddress.postalCode,
        company_phone: orderData5CU.accountInfo.businessAddress.phone
          ? orderData5CU.accountInfo.businessAddress.phone
          : orderData5CU.accountInfo.billingAddress.phone,
        sales_rep_id: orderData5CU.accountInfo.salesRepCode
          ? orderData5CU.accountInfo.salesRepCode
          : null, //need to check
        on_behalf_of: orderData5CU.accountInfo.behalfEmail
          ? orderData5CU.accountInfo.behalfEmail
          : null,
        xp_code: orderData5CU.accountInfo.xpCode
          ? orderData5CU.accountInfo.xpCode
          : null,
        abn: orderData5CU.accountInfo.abn ? orderData5CU.accountInfo.abn : null, //need to check,
        thryv_id: orderData5CU.order.CCAccountInfo.thryvId,
        account_code: orderData5CU.recurlyAccount?.code
          ? orderData5CU.recurlyAccount?.code
          : orderData5CU.order.CCAccountInfo?.ccId
          ? orderData5CU.order.CCAccountInfo?.ccId
          : 'null',
        eaid: orderData5CU.accountInfo.eaid
          ? orderData5CU.accountInfo.eaid
          : 'null',
        is_invoice_billed: orderData5CU.accountInfo.allowInvoice ? 1 : 0,
        is_cohort: null, //need to check,
        demo_lead_id: null,
        no_service: null, //need to check
        onboard_appt_id: order5OnboardingApptId,
        creative_appt_id: order5CreativeApptId,
        update_to_autopay: 1, //check
        on_boarding_fee: 0, //need to check
        purchase_intent_1: null, //need to check,
        purchase_intent_2: null, //need to check,
        purchase_intent_3: null, //need to check,
        sensis_site: null, //need to check,
        lines: [],
        discounts: [],
        reseller: [],
      }

      if (context === 'catalog-continue-summary') {
        data.order_status = 'Pending'
        data.lines = contextData
        data.discounts = order5LinesDiscounts
        setOrder5SelectedLines(contextData)
      } else if (context === 'capture-appointment') {
        console.log(
          'order5SelectedLines - upgrade - capture-appointment',
          order5SelectedLines,
        )
        console.log(
          'order5LinesDiscounts - upgrade - capture-appointment',
          order5LinesDiscounts,
        )
        if (contextData.appointment_type === 'creative') {
          data.creative_appt_id = contextData.apptId
        } else if (contextData.appointment_type === 'onboarding') {
          data.onboard_appt_id = contextData.apptId
        }
        data.lines = order5SelectedLines
        data.discounts = order5LinesDiscounts
      } else {
        console.log(
          'order5SelectedLines - upgrade - other context',
          order5SelectedLines,
        )
        console.log(
          'order5LinesDiscounts - upgrade - other context',
          order5LinesDiscounts,
        )
        data.lines = order5SelectedLines
        data.discounts = order5LinesDiscounts
      }

      if (isReseller) {
        const resellerData = getResellerData(data)
        console.log('resellerData', resellerData)
        if (resellerData) {
          data.reseller.push(resellerData)
        }
      }

      if (!order5NUId) {
        console.log('createOrderData - upgrade', data)
        const result = await run(
          client('ordercapture/services/createOrder', {data, method: 'POST'}),
        )
        console.log('order created - upgrade', result)
        setOrder5NUId(result.order_id)
      } else {
        data.order_id = order5NUId
        console.log('UpdateOrderData - upgrade', data)
        const result = await run(
          client('ordercapture/services/updateOrder', {data, method: 'POST'}),
        )
        console.log('order updated - upgrade', result)
      }
    } catch (error) {
      if (error.status !== 400) {
        console.log(`Error creating/updating order: ${error.message}`)
      }
      if (error.errors) {
        // error.errors.forEach(({message}) => toast.error(message))
        error.errors.forEach(({message}) => console.log(message))
      } else {
        console.log(`Error creating/updating order: ${error.message}`)
      }
    }
  }

  const getResellerData = data => {
    if (data.lines && data.lines.length > 0) {
      const nonCcProducts = [
        'cc_basicpls-5-thryv-m2m',
        'appmarket-5-thryv-m2m',
        'addon-5-signatures_usage',
        'app-websitebuilder',
      ]
      const isnonCCPrduct = data.lines.every(line =>
        nonCcProducts.some(prdct => prdct === line.saas_product_code),
      )
      console.log('isnonCCPrduct', isnonCCPrduct)
      if (!isnonCCPrduct) {
        const resellerData = {
          account_code: orderData5CU.recurlyAccount?.code
            ? orderData5CU.recurlyAccount?.code
            : null,
          account_id: orderData5CU.recurlyAccount?.billingInfo?.accountId
            ? orderData5CU.recurlyAccount?.billingInfo?.accountId
            : orderData5CU.recurlyAccount?.id
            ? orderData5CU.recurlyAccount?.id
            : null,
          no_service: null,
          original_selling_coupon_code: null,
          subscription_id: orderData5CU.order.subscriptionId
            ? orderData5CU.order.subscriptionId
            : null,
          thryv_id: orderData5CU.order.CCAccountInfo.thryvId,
          uuid: null,
        }
        return resellerData
      } else {
        return null
      }
      // return null
    }
  }

  const updateOrder5Status = async (context, orderFlow, contextData) => {
    console.log('updateOrder5Status() - context', context)
    console.log('updateOrder5Status() - orderFlow', orderFlow)
    console.log('updateOrder5Status() - contextData', contextData)
    console.log('captureOrder5NewUpgrade() - orderData5CU', orderData5CU)
    try {
      const statusData = {
        order_id: order5NUId,
        order_status: 'Pending',
      }
      if (context === 'send-link') {
        statusData.order_status = 'Submitted'
      } else if (context === 'cancel-order') {
        statusData.order_status = 'Cancelled'
      }
      const result = await run(
        client('ordercapture/services/updateOrderStatus', {
          data: statusData,
          method: 'PUT',
        }),
      )
      console.log('order updated - upgrade - status', result)
    } catch (error) {
      if (error.status !== 400) {
        console.log(`Error updating order status: ${error.message}`)
        // throw error
      }
      if (error.errors) {
        // error.errors.forEach(({message}) => toast.error(message))
        error.errors.forEach(({message}) => console.log(message))
      } else {
        console.log(`Error updating order status: ${error.message}`)
      }
    }
  }

  const updateApptOnExpire = async (appointment_type, apptId) => {
    const appointmentObj = {
      order_id: order5NUId,
      schedule_status: 'Expired',
      appointment_type: appointment_type,
      appt_id: apptId,
    }
    try {
      // const data = appointmentObj
      const result = await run(
        client('ordercapture/services/updateScheduledAppointment', {
          data: appointmentObj,
          method: 'PUT',
        }),
      )
      if (result.success) {
        if (appointment_type === 'creative') {
          setOrder5CreativeApptId(null)
        } else if (appointment_type === 'onboarding') {
          setOrder5OnboardingApptId(null)
        }
        const contextData = {
          appointment_type: appointment_type,
          apptId: null,
        }
        captureOrder5NewUpgrade(
          'capture-appointment',
          'new-upgrade',
          contextData,
        )
      } else {
        console.log(result.message)
      }
    } catch (error) {
      if (error.status !== 400) {
        console.log('error in cancelling Appointment', error)
        // throw error
      }
      if (error.errors) {
        console.log('error in cancelling Appointment', error)
        // throw error
      }
    }
  }

  const captureOrderDiscounts = async discountData => {
    const allDiscount = []
    let lineCounter = 1
    for (let data of discountData) {
      console.log('disData', data)
      // const today = new Date()
      const dataObj = {
        order_id: order5NUId,
        line_number: lineCounter,
        discount_type: data.type,
        coupon_code: data.code,
        discount_percentage: data.discountPercent,
        discount_amount: data.discountAmount,
        number_of_months: data.durationAmount,
        start_date: new Date(),
        end_date: datePlusDuration(
          new Date(),
          data.durationAmount,
          data.durationUnit,
        ),
        created_date: null,
        create_oprid: null,
        updated_date: null,
        update_oprid: null,
      }
      allDiscount.push(dataObj)
      lineCounter = lineCounter + 1
    }
    setOrder5LinesDiscounts(allDiscount)
    // captureOrder5NewUpgrade('preview-payment', 'new-upgrade', allDiscount)
  }

  function datePlusDuration(date, duration, unit) {
    const newDate = new Date(date)
    if (unit === 'month') newDate.setMonth(newDate.getMonth() + duration)
    if (unit === 'day') newDate.setDate(newDate.getDate() + duration)
    if (unit === 'year') newDate.setFullYear(newDate.getFullYear() + duration)
    return newDate
  }

  const captureOrder4NewUpgrade = async (context, orderFlow, contextData) => {
    try {
      console.log('captureOrder4NewUpgrade() - context', context)
      console.log('captureOrder4NewUpgrade() - orderFlow', orderFlow)
      console.log('captureOrder4NewUpgrade() - contextData', contextData)
      console.log('captureOrder4NewUpgrade() - orderData4CU', orderData4CU)
      console.log(
        'captureOrder4NewUpgrade() - ccUpgradeCoupons',
        ccUpgradeCoupons,
      )

      const data = {
        order_status: 'Pending',
        order_version: '4.0',
        order_flow: 'Update',
        order_submitted_date: new Date(),
        order_submitted_by: 'null',
        order_completed_date: null,
        industry_id: orderData4CU.order.industryCategoryId.toString(),
        industry_description: orderData4CU.order.industryCategoryDescription,
        country_code: orderData4CU.order.selectedCountry.isoValue,
        account_email: orderData4CU.accountInfo.recurlyEmail,
        contact_first_name: orderData4CU.accountInfo.recurlyFirst,
        contact_last_name: orderData4CU.accountInfo.recurlyLast,
        contact_phone: orderData4CU.accountInfo.recurlyPhone
          ? orderData4CU.accountInfo.recurlyPhone.toString()
          : orderData4CU.accountInfo.billingAddress.phone.toString(), // check
        contact_mobile: orderData4CU.accountInfo.recurlyPhone
          ? orderData4CU.accountInfo.recurlyPhone.toString()
          : orderData4CU.accountInfo.billingAddress.phone.toString(), // check
        contact_email: orderData4CU.accountInfo.recurlyEmail,
        company_name: orderData4CU.accountInfo.recurlyCompany,
        company_address1: orderData4CU.accountInfo.recurlyAddress,
        company_address2: orderData4CU.accountInfo.recurlyStreet,
        company_city: orderData4CU.accountInfo.billingAddress.city,
        company_state: orderData4CU.accountInfo.billingAddress.region,
        company_zipcode: orderData4CU.accountInfo.billingAddress.postalCode,
        company_phone: orderData4CU.accountInfo.recurlyPhone
          ? orderData4CU.accountInfo.recurlyPhone.toString()
          : orderData4CU.accountInfo.billingAddress.phone.toString(),
        sales_rep_id: orderData4CU.accountInfo.salesRepCode
          ? orderData4CU.accountInfo.salesRepCode
          : null, //need to check
        on_behalf_of: orderData4CU.accountInfo.behalfEmail
          ? orderData4CU.accountInfo.behalfEmail
          : null,
        xp_code: orderData4CU.accountInfo.xpCode
          ? orderData4CU.accountInfo.xpCode
          : null,
        abn: orderData4CU.accountInfo.abn ? orderData4CU.accountInfo.abn : null, //need to check,
        thryv_id: orderData4CU.order.thryvID
          ? orderData4CU.order.thryvID
          : orderData4CU.accountInfo.thryvID,
        account_code: orderData4CU.accountInfo.thryvID
          ? orderData4CU.accountInfo.thryvID
          : orderData4CU.order.thryvID,
        eaid: orderData4CU.accountInfo.eaid
          ? orderData4CU.accountInfo.eaid
          : 'null',
        is_invoice_billed: orderData4CU.accountInfo.allowInvoice ? 1 : 0,
        is_cohort: null, //need to check,
        demo_lead_id: null,
        no_service: null, //need to check
        onboard_appt_id: order5OnboardingApptId,
        creative_appt_id: order5CreativeApptId,
        update_to_autopay: 1, //check
        on_boarding_fee: 0, //need to check
        purchase_intent_1: null, //need to check,
        purchase_intent_2: null, //need to check,
        purchase_intent_3: null, //need to check,
        sensis_site: null, //need to check,
        lines: [],
        discounts: [],
        reseller: [],
      }

      if (context === 'addons-selection-continue') {
        data.order_status = 'Pending'
        data.lines = contextData
        setOrder4SelectedLines(contextData)
      } else if (context === 'capture-appointment') {
        console.log(
          'order4SelectedLines - upgrade - capture-appointment',
          order4SelectedLines,
        )
        console.log(
          'order4LinesDiscounts - upgrade - capture-appointment',
          order4LinesDiscounts,
        )
        if (contextData.appointment_type === 'creative') {
          data.creative_appt_id = contextData.apptId
        } else if (contextData.appointment_type === 'onboarding') {
          data.onboard_appt_id = contextData.apptId
        }
        data.lines = order4SelectedLines
        data.discounts = order4LinesDiscounts
      } else if (context === 'apply-coupon') {
        console.log(
          'order4SelectedLines - upgrade - apply-coupon',
          order4SelectedLines,
        )
        console.log(
          'order4LinesDiscounts - upgrade - apply-coupon',
          order4LinesDiscounts,
        )
        if (contextData.appointment_type === 'creative') {
          data.creative_appt_id = contextData.apptId
        } else if (contextData.appointment_type === 'onboarding') {
          data.onboard_appt_id = contextData.apptId
        }
        data.lines = order4SelectedLines
        data.discounts = contextData
        setOrder4LinesDiscounts(contextData)
      } else {
        console.log(
          'order4SelectedLines - upgrade - other context',
          order4SelectedLines,
        )
        console.log(
          'order4LinesDiscounts - upgrade - other context',
          order4LinesDiscounts,
        )
        data.lines = order4SelectedLines
        data.discounts = order4LinesDiscounts
      }

      if (isReseller) {
        const resellerData = getResellerData4Order(data)
        console.log('resellerData', resellerData)
        if (resellerData) {
          data.reseller.push(resellerData)
        }
      }

      if (!order5NUId) {
        console.log('createOrderData - upgrade', data)
        const result = await run(
          client('ordercapture/services/createOrder', {data, method: 'POST'}),
        )
        console.log('order created - upgrade', result)
        setOrder5NUId(result.order_id)
      } else {
        data.order_id = order5NUId
        console.log('UpdateOrderData - upgrade', data)
        const result = await run(
          client('ordercapture/services/updateOrder', {data, method: 'POST'}),
        )
        console.log('order updated - upgrade', result)
      }
    } catch (error) {
      if (error.status !== 400) {
        console.log(`Error creating/updating order: ${error.message}`)
      }
      if (error.errors) {
        // error.errors.forEach(({message}) => toast.error(message))
        error.errors.forEach(({message}) => console.log(message))
      } else {
        console.log(`Error creating/updating order: ${error.message}`)
      }
    }
  }

  const getResellerData4Order = data => {
    if (data.lines && data.lines.length > 0) {
      const resellerData = {
        account_code: orderData4CU.accountInfo.thryvID
          ? orderData4CU.accountInfo.thryvID
          : orderData4CU.order.thryvID,
        account_id: orderData4CU.accountInfo.recurlyAccountId
          ? orderData4CU.accountInfo.recurlyAccountId
          : null,
        no_service: null,
        original_selling_coupon_code: null,
        subscription_id: orderData4CU.order.subscriptionId
          ? orderData4CU.order.subscriptionId
          : null,
        thryv_id: orderData4CU.accountInfo.thryvId,
        uuid: null,
      }
      return resellerData
    }
  }

  const debouncedOrderCouponCodes = useDebounce(orderCouponCodes, 500)

  const getUpgradeCouponData = useCallback(async () => {
    let upgradeCoupons = []
    const transitionCouponCodes = ['25month1', '25month2', '25month3']
    const redeemedCoupons = await client(
      `accounts/5/coupon-redemptions?thryvId=${
        order.CCAccountInfo.thryvId || accountInfo.thryvID
      }`,
    )
    const hasTransitionCoupons = redeemedCoupons.some(({code}) =>
      transitionCouponCodes.includes(code),
    )
    const transitionApplies =
      defaultCentersAndApps.current.centers.filter(
        ({name}) =>
          !name.includes('SEO') &&
          name !== 'Thryv Leads' &&
          !name.includes('Basic'),
      ).length === 0 && !hasTransitionCoupons

    if (
      orderCouponCodes.find(x => x.code === 'transition75') &&
      transitionApplies
    ) {
      const {code} = order.selectedCentersAndApps.centers.find(({name}) =>
        CC_PLANS.some(plan => name.toLowerCase().includes(plan)),
      )
      const createUrl = couponCode =>
        `orders/coupon/validate?couponCode=${couponCode}&country=${accountInfo.billingAddress.country}&planCode=${code}`
      const [transitionCoupon1, transitionCoupon2, transitionCoupon3] =
        await Promise.all(
          transitionCouponCodes.map(async code => {
            const url = createUrl(code)
            return await client(url)
          }),
        )
      const transitionCouponIndex = orderCouponCodes.findIndex(
        addonInfo => addonInfo.code === 'transition75',
      )
      const couponsCopy = [...orderCouponCodes]

      couponsCopy.splice(transitionCouponIndex, 1)
      couponsCopy.push(transitionCoupon1, transitionCoupon2, transitionCoupon3)

      // captureOrderDiscounts(couponsCopy)
      const COUPON_CODES = couponsCopy
        .filter(coupon => {
          return coupon.isValid
        })
        .map(coupon => {
          return coupon.code
        })
      if (COUPON_CODES.length > 0) {
        upgradeCoupons = COUPON_CODES
      }
      const discountsCoupon = couponsCopy.filter(coupon => {
        return coupon.isValid
      })
      captureOrderDiscounts(discountsCoupon)
    } else {
      const ALL_COUPON_CODES = orderCouponCodes
        .filter(coupon => {
          return coupon.isValid && coupon.code !== 'transition75'
        })
        .map(coupon => {
          return coupon.code
        })
      if (ALL_COUPON_CODES.length > 0) {
        upgradeCoupons = ALL_COUPON_CODES
      } else {
        upgradeCoupons = []
      }
      const discountsCoupon = orderCouponCodes.filter(coupon => {
        return coupon.isValid && coupon.code !== 'transition75'
      })
      captureOrderDiscounts(discountsCoupon)
    }
    setCcUpgradeCoupons(upgradeCoupons)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedOrderCouponCodes])

  useEffect(() => {
    if (!isUpgrade || !isCCPlan || currentStep < 2) return

    getUpgradeCouponData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUpgradeCouponData])

  useEffect(() => {
    if (
      !frontendPreviewPayment ||
      !isUpgrade ||
      !ccUpgradeCoupons ||
      !order.selectedCentersAndApps ||
      upgradePaymentPreview
    )
      return

    getPaymentPreview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccUpgradeCoupons])

  useEffect(() => {
    if (!frontendPreviewPayment) {
      setPreviewRequestFinished(true)
    }
  }, [frontendPreviewPayment])

  useEffect(() => {
    if (order.billingAccountId) fetchAccountInfo()
  }, [fetchAccountInfo, order.billingAccountId])

  useEffect(() => {
    if (ccCouponInfo && orderCouponCodes.length === 0) {
      setOrderCouponCodes([ccCouponInfo])
    }
  }, [ccCouponInfo, orderCouponCodes])

  return (
    <Container frontendSideNav={frontendSideNav}>
      <MainContainer>
        <WizardContainer>
          {isInvoiceBilled ? (
            <StepsContainer>
              <NewOrderLayout
                prevStep={prevStep}
                showContinueButton={false}
                title="Invoices"
                showBackButton={true}
                cohortEaid={cohortEaid}
                setCohortEaid={setCohortEaid}
              >
                <InvoiceInformation
                  thryvId={accountInfo.thryvID}
                  email={accountInfo.recurlyEmail}
                  setIsInvoiceBilled={setIsInvoiceBilled}
                />
              </NewOrderLayout>
            </StepsContainer>
          ) : (
            <>
              {isLoading ? (
                <div className="text-center p-3">
                  <SkeletonLoader width="90%" height="50vh" />
                </div>
              ) : (
                <StepsContainer>
                  {currentStep === 0 && (
                    <CountrySelection nextStep={updateBillingAddress} />
                  )}
                  {currentStep === 1 && !isCCPlan && (
                    <UpgradePackageSelection
                      nextStep={nextStep}
                      prevStep={prevStep}
                      selectedCountry={accountInfo.billingAddress.country}
                      accountInfo={accountInfo}
                      updateBillingAddress={updateBillingAddress}
                      order5NUId={order5NUId}
                      isCancelOrder5={true}
                      cancelOrder5={updateOrder5Status}
                    />
                  )}
                  {currentStep === 1 && isCCPlan && order?.CCAccountInfo && (
                    <Catalog
                      nextStep={nextStep}
                      prevStep={prevStep}
                      updateAddressInfo={updateAddressInfo}
                      selectedCountry={accountInfo?.billingAddress?.country}
                      CCAccountInfo={order.CCAccountInfo}
                      industryTemplate={order.template}
                      prevCentersAndApps={order?.selectedCentersAndApps}
                      prevCode={order?.code}
                      ccCouponInfo={ccCouponInfo}
                      setCcCouponInfo={setCcCouponInfo}
                      setAccountInfo={setAccountInfo}
                      setAccountSubscriptionData={setAccountSubscriptionData}
                      accountSubscriptionData={accountSubscriptionData}
                      accountInfo={accountInfo}
                      setCurrentStep={setCurrentStep}
                      order={order}
                      setOrder={setOrder}
                      defaultUpgradeCentersAndApps={defaultCentersAndApps}
                      defaultExpiredCentersAndApps={expiredCentersAndApps}
                      setOrderCouponCodes={setOrderCouponCodes}
                      orderCouponCodes={orderCouponCodes}
                      setIncentiveCoupon={setIncentiveCoupon}
                      all5PlanPrices={all5PlanPrices}
                      isEaidFound={isEaidFound}
                      setIsEaidFound={setIsEaidFound}
                      cohortEaid={cohortEaid}
                      setCohortEaid={setCohortEaid}
                      upgradePaymentPreview={upgradePaymentPreview}
                      setUpgradePaymentPreview={setUpgradePaymentPreview}
                      setPreviewRequestFinished={setPreviewRequestFinished}
                      ccUpgradeCoupons={ccUpgradeCoupons}
                      setCcUpgradeCoupons={setCcUpgradeCoupons}
                      resetAutoCoupons={resetAutoCoupons}
                      setAllowInvoice={setAllowInvoice}
                      allowInvoice={allowInvoice}
                      isCohort={isCohort}
                      setIsCohort={setIsCohort}
                      orderData5CU={orderData5CU}
                      setOrderData5CU={setOrderData5CU}
                      // order5NUId={order5NUId}
                      // setOrder5NUId={setOrder5NUId}
                      captureOrder5NewUpgrade={captureOrder5NewUpgrade}
                    />
                  )}
                  {currentStep === 2 && !isCCPlan && (
                    <UpgradeAddonsSelection
                      nextStep={nextStep}
                      prevStep={prevStep}
                      selectedPlan={order.selectedPlan}
                      billingPreference={order.billingPreference}
                      selectedCountry={
                        COUNTRIES[accountInfo.billingAddress.country].value
                      }
                      currentGmailItems={getGmailItems()}
                      industryCategoryType={order.selectedPlan.industryType}
                      selectedIndustry={accountInfo.industry}
                      accountInfo={accountInfo}
                      accountSubscriptionData={accountSubscriptionData}
                      isWebsiteIncluded={isWebsiteIncluded}
                      all5PlanPrices={all5PlanPrices}
                      isTSS={isTSS}
                      isEaidFound={isEaidFound}
                      cohortEaid={cohortEaid}
                      setIsEaidFound={setIsEaidFound}
                      setCohortEaid={setCohortEaid}
                      setAllowInvoice={setAllowInvoice}
                      setIsCohort={setIsCohort}
                      orderData4CU={orderData4CU}
                      order5NUId={order5NUId}
                      isCancelOrder5={true}
                      cancelOrder5={updateOrder5Status}
                      captureOrder4NewUpgrade={captureOrder4NewUpgrade}
                    />
                  )}
                  {((currentStep === 3 && !isCCPlan) ||
                    (currentStep === 2 && isCCPlan)) && (
                    <OnboardingProgram
                      nextStep={nextStep}
                      prevStep={prevStep}
                      selectedPlan={order.selectedPlan}
                      billingPreference={order.billingPreference}
                      accountInformation={{
                        mobile:
                          accountInfo?.billingAddress?.mobile ||
                          accountInfo?.recurlyPhone,
                        email: accountInfo?.recurlyEmail,
                        lastName: accountInfo?.recurlyLast,
                        firstName: accountInfo?.recurlyFirst,
                        country: accountInfo?.billingAddress?.country,
                        state: accountInfo?.billingAddress?.state,
                        city: accountInfo?.billingAddress?.city,
                        address: accountInfo?.billingAddress?.street1,
                        company: accountInfo?.recurlyCompany,
                        selectedPackage: selectedPlanCode,
                        hasPaidOnboardingFee:
                          accountInfo.hasPaidOnboardingFee ||
                          accountSubscriptionData?.accountInfo
                            ?.hasPaidOnboardingFee,
                        hasPaidDesignFee:
                          accountInfo.hasPaidDesignFee ||
                          accountSubscriptionData?.accountInfo
                            ?.hasPaidDesignFee,
                      }}
                      industryCategoryType={
                        isCCPlan
                          ? order.template
                          : order.selectedPlan.industryType
                      }
                      selectedAddons={order.selectedAddons}
                      allSelectedPurchaseIntent={allSelectedPurchaseIntent}
                      setAllSelectedPurchaseIntent={
                        setAllSelectedPurchaseIntent
                      }
                      currentPlan={accountInfo.planCode}
                      currentPlanName={accountInfo.planName}
                      setKickoffApptId={setKickoffApptId}
                      kickoffApptId={kickoffApptId}
                      creativeApptId={creativeApptId}
                      setCreativeApptId={setCreativeApptId}
                      setCartProducts={setCartProducts}
                      cartProducts={cartProducts}
                      isWebsiteIncluded={isWebsiteIncluded}
                      isSocialBoostIncluded={isSocialBoostIncluded}
                      onboardingCallDate={onboardingCallDate}
                      setOnboardingCallDate={setOnboardingCallDate}
                      setCreativeCallDate={setCreativeCallDate}
                      creativeCallDate={creativeCallDate}
                      selectedCountry={
                        COUNTRIES[accountInfo.billingAddress.country]
                      }
                      selectedCentersAndApps={order.selectedCentersAndApps}
                      orderCouponCodes={orderCouponCodes}
                      setOrderCouponCodes={setOrderCouponCodes}
                      defaultCentersAndApps={defaultCentersAndApps.current}
                      expiredCentersAndApps={expiredCentersAndApps.current}
                      setIncentiveCoupon={setIncentiveCoupon}
                      incentiveCoupon={incentiveCoupon}
                      upgradeAccountInfo={accountInfo}
                      ccCouponInfo={ccCouponInfo}
                      ccUpgradeCoupons={ccUpgradeCoupons}
                      upgradePaymentPreview={upgradePaymentPreview}
                      previewRequestFinished={previewRequestFinished}
                      eligibleTransitionCenters={eligibleTransitionCenters}
                      isCohort={isCohort}
                      order5NUId={order5NUId}
                      isCancelOrder5={true}
                      cancelOrder5={updateOrder5Status}
                      captureOrderNewUpgrade={
                        isCCPlan
                          ? captureOrder5NewUpgrade
                          : captureOrder4NewUpgrade
                      }
                      order5OnboardingApptId={order5OnboardingApptId}
                      order5CreativeApptId={order5CreativeApptId}
                      setOrder5CreativeApptId={setOrder5CreativeApptId}
                      setOrder5OnboardingApptId={setOrder5OnboardingApptId}
                    />
                  )}
                  {((currentStep === 4 && !isCCPlan) ||
                    (currentStep === 3 && isCCPlan)) && (
                    <PaymentDetails
                      sendPaymentLink={sendPaymentLink}
                      resendPaymentLink={resendPaymentLink}
                      checkClientEmailStatus={checkClientEmailStatus}
                      showSmsOption={showSmsOption}
                      setShwoSmsOption={setShwoSmsOption}
                      prevStep={prevStep}
                      nextStep={nextStep}
                      selectedAddons={order.selectedAddons}
                      accountInformation={{
                        mobile:
                          accountInfo.billingAddress.mobile ||
                          accountInfo.recurlyPhone,
                        package: selectedPlanCode,
                      }}
                      planCode={selectedPlanCode}
                      countryIsoValue={accountInfo.billingAddress.country}
                      setOrderCouponCodes={setOrderCouponCodes}
                      orderCouponCodes={orderCouponCodes}
                      setKickoffApptId={setKickoffApptId}
                      kickoffApptId={kickoffApptId}
                      creativeApptId={creativeApptId}
                      setCreativeApptId={setCreativeApptId}
                      accountInfo={accountInfo}
                      selectedPlan={order.selectedPlan}
                      setCartProducts={setCartProducts}
                      cartProducts={cartProducts}
                      processing={processing}
                      setProcessing={setProcessing}
                      selectedCountry={accountInfo.billingAddress.country}
                      setCreativeCallDate={setCreativeCallDate}
                      setOnboardingCallDate={setOnboardingCallDate}
                      clientEmail={accountInfo.recurlyEmail}
                      selectedCentersAndApps={order?.selectedCentersAndApps}
                      sendCCPaymentLink={sendCCPaymentLink}
                      ccCouponInfo={ccCouponInfo}
                      onboardingCallDate={onboardingCallDate}
                      creativeCallDate={creativeCallDate}
                      isCCPlan={isCCPlan}
                      defaultCentersAndApps={defaultCentersAndApps.current}
                      expiredCentersAndApps={expiredCentersAndApps.current}
                      incentiveCoupon={incentiveCoupon}
                      isSEOIncluded={isSEOIncluded}
                      autoCouponInfo={autoCouponInfo}
                      upgradePaymentPreview={upgradePaymentPreview}
                      isInvoiceBilled={isInvoiceBilled}
                      allowInvoice={allowInvoice}
                      isCohort={isCohort}
                      setSelectedPaymentMethod={setSelectedPaymentMethod}
                      previewRequestFinished={previewRequestFinished}
                      selectedPaymentMethod={selectedPaymentMethod}
                      cohortEaid={cohortEaid}
                      setCohortEaid={setCohortEaid}
                      orderData5CU={orderData5CU}
                      orderData4CU={orderData4CU}
                      order5NUId={order5NUId}
                      isCancelOrder5={true}
                      cancelOrder5={updateOrder5Status}
                      updateApptOnExpire={updateApptOnExpire}
                      captureOrder4NewUpgrade={captureOrder4NewUpgrade}
                    />
                  )}
                  {currentStep === 5 && isTSS && !isCCPlan && (
                    <TSSConfirmation />
                  )}
                  {((currentStep === 5 && !isTSS && !isCCPlan) ||
                    (currentStep === 4 && isCCPlan)) && (
                    <Commitment
                      prevStep={prevStep}
                      clientInitials={clientInitials}
                      updateInitials={updateInitials}
                      purchaseIntents={allSelectedPurchaseIntent}
                      onboardingCallDate={onboardingCallDate}
                      creativeCallDate={creativeCallDate}
                      clientEmail={null}
                    />
                  )}
                </StepsContainer>
              )}
            </>
          )}
        </WizardContainer>
      </MainContainer>
    </Container>
  )
}

export {UpgradeOrder}
