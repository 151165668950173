import React, {useState, useEffect} from 'react'
import {useAsync} from '../utils/use-async'
import {useLocation} from 'react-router-dom'
import {CountrySelection} from './country-selection'
import {useAuthClient} from '../utils/use-auth-client'
import {IndustryCategorySelection} from './industry-category-selection'
import {AccountInformation} from './account-information'
import {IndustrySelection} from './industry-selection'
import {toast} from 'react-toastify'
import {OnboardingProgram} from './onboarding-program'
import PaymentDetails from './payment-details'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {useTrackLR} from '../utils/use-track-lr'
import {Catalog} from './catalog'
import useAutoCoupons from '../utils/use-auto-coupons'
import {useUserRoles} from '../utils/use-user-roles'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'

import {CC_CURRENCIES, CC_PLANS} from '../utils/constants'
import {Commitment} from './commitment'

import {
  MainContainer,
  WizardContainer,
  StepsContainer,
} from '../components/ui/new-order'

function Container({children, frontendSideNav}) {
  if (frontendSideNav) {
    return <div className="d-flex">{children}</div>
  }
  return children
}

function NewOrder() {
  const location = useLocation()
  const {frontendSideNav} = useFlags()
  const {state} = location
  const [order, setOrder] = useState(state?.order || {})
  const [isNewAccountOrderCreated, setIsNewAccountOrderCreated] =
    useState(false)
  const [orderData5CU, setOrderData5CU] = useState({})
  const [order5NUId, setOrder5NUId] = useState(null)
  const [order5SelectedLines, setOrder5SelectedLines] = useState({})
  const [order5LinesDiscounts, setOrder5LinesDiscounts] = useState([])
  const [order5OnboardingApptId, setOrder5OnboardingApptId] = useState(null)
  const [order5CreativeApptId, setOrder5CreativeApptId] = useState(null)
  const [currentStep, setCurrentStep] = useState(state?.currentStep || 1)
  const [accountSubscriptionData, setAccountSubscriptionData] = useState({})
  const [kickoffApptId, setKickoffApptId] = useState(null)
  const [creativeApptId, setCreativeApptId] = useState(null)
  const [onboardingCallDate, setOnboardingCallDate] = useState(null)
  const [creativeCallDate, setCreativeCallDate] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [allowInvoice, setAllowInvoice] = useState(false)
  const [showSmsOption, setShwoSmsOption] = useState(false)
  const [accountInfo, setAccountInfo] = useState({})
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('Credit Card')
  const [allSelectedPurchaseIntent, setAllSelectedPurchaseIntent] = useState({
    firstPurchaseIntent: {},
    secondPurchaseIntent: {},
    thirdPurchaseIntent: {},
    csmExtraInfo: '',
  })
  const [orderCouponCodes, setOrderCouponCodes] = useState([])
  const [clientInitials, setClientInitials] = useState('')
  const [cartProducts, setCartProducts] = useState([])
  const [ccCouponInfo, setCcCouponInfo] = useState()
  const [incentiveCoupon, setIncentiveCoupon] = React.useState(false)
  const [all5PlanPrices, setAll5PlanPrices] = useState(null)
  const [isEaidFound, setIsEaidFound] = useState(false)
  const [cohortEaid, setCohortEaid] = useState(false)
  const [isCohort, setIsCohort] = useState(false)
  const client = useAuthClient()
  const {roles} = useUserRoles()
  console.log('user roles', roles)
  const isReseller = getAuthByRole(['reseller'], roles)

  const {run} = useAsync({
    status: 'pending',
  })
  const customEvent = order?.accountInformation && {
    key: 'new-order',
    companyEmail: order.accountInformation.email,
    companyName: order.accountInformation.company,
  }
  useTrackLR(customEvent)
  const {autoCouponInfo, resetAutoCoupons} = useAutoCoupons({
    selectedPackage: order.accountInformation?.selectedPackage,
    selectedIndustryId: order.industryCategoryId,
    selectedCountry: order.selectedCountry,
    selectedCentersAndApps: order.selectedCentersAndApps,
    selectedAddons: order.selectedAddons,
    incentiveCoupon,
    setIncentiveCoupon,
    currentStep,
    setOrderCouponCodes,
    ccCouponInfo,
    isEaidFound,
    isCohort,
  })

  const TOTAL_STEPS = 9
  const isSEOIncluded = order?.selectedCentersAndApps?.centers?.some(({name}) =>
    name.includes('SEO'),
  )

  useEffect(() => {
    const get5PlanPrices = async () => {
      const url = 'plans/5/list'
      const response = await client(url)
      setAll5PlanPrices(response)
    }

    get5PlanPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (kickoffApptId) {
      return () => {
        client(`plans/onboarding`, {
          data: {
            type: 'onboarding',
            country: order?.selectedCountry?.isoValue,
            demoApptId: kickoffApptId,
          },
          method: 'DELETE',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kickoffApptId])

  useEffect(() => {
    if (creativeApptId) {
      return () => {
        client(`plans/creative`, {
          data: {
            type: 'creative',
            country: order?.selectedCountry?.isoValue,
            demoApptId: creativeApptId,
          },
          method: 'DELETE',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeApptId])

  useEffect(() => {
    if (ccCouponInfo && orderCouponCodes.length === 0) {
      setOrderCouponCodes([ccCouponInfo])
    }
  }, [ccCouponInfo, orderCouponCodes])

  const nextStep = data => {
    console.log('order obj - next step - new order.js', order)
    console.log('order data - next step - new order.js', data)

    setOrder({...order, ...data})
    let _currentStep = currentStep
    if (currentStep >= TOTAL_STEPS - 1) {
      _currentStep = TOTAL_STEPS
    } else {
      _currentStep = currentStep + 1
    }
    setCurrentStep(_currentStep)
  }
  const prevStep = () => {
    let _currentStep = currentStep
    if (currentStep <= 1) {
      _currentStep = 1
    } else {
      _currentStep = currentStep - 1
    }
    setCurrentStep(_currentStep)
  }

  const updateAddressInfo = (data, setShowModal) => {
    const insertData = {
      ...order,
      accountInformation: {
        ...order?.accountInformation,
        address: data?.street1,
        postCode: data?.postalCode,
        state: data?.region,
        companyPhone: data?.phone,
        ...data,
      },
    }

    delete insertData.accountInformation.postalCode
    delete insertData.accountInformation.street1
    delete insertData.accountInformation.region
    delete insertData.accountInformation.phone

    setOrder(insertData)
    setShowModal(false)
    setOrderData5CU({
      ...orderData5CU,
      accountInfo: {...data},
    })
  }

  const updateInitials = initials => {
    setClientInitials(initials)
  }

  const findWebsiteEntitlement = centers =>
    centers
      .find(({entitlements}) =>
        entitlements.some(
          ({name}) => name === 'Professionally Designed Website',
        ),
      )
      ?.entitlements.find(
        ({name}) => name === 'Professionally Designed Website',
      )

  const findWebsiteApp = ({name}) => name === 'Professionally Designed Website'

  const formatCenters = (centers, callbackFn) =>
    centers
      .filter(callbackFn)
      .map(({code, quantity = 1, entitlements, startDate}) => ({
        code,
        quantity,
        ...(startDate && {startDate}),
        addons: entitlements.map(({code, quantity = 1}) => ({code, quantity})),
      }))
  const sendCCPaymentLink = async (contactMethod, contactInfo) => {
    const demoLeadId = order.accountInformation.leadID || order.leadId
    const {centers, apps, oneTimes} = order.selectedCentersAndApps
    const thryvLeadsId = centers.find(
      ({name}) => name === 'Thryv Leads',
    )?.thryvLeadsId
    // const seoProposal = centers.find(({name}) => name.includes('SEO'))
    const seoProposal = centers.find(
      ({name}) => name.includes('SEO') && name !== 'SEO',
    )
    const alphaSeo = centers.find(({name}) => name === 'SEO')
    const sensisDomain = (
      findWebsiteEntitlement(centers) || apps.find(findWebsiteApp)
    )?.sensisSiteDomain
    const mcDomain = centers.find(({name}) =>
      name.toLowerCase().includes('marketing'),
    )?.mcDomain
    const parentSubscriptions = formatCenters(centers, ({name}) =>
      name.includes('Command Center'),
    )
    const marketplace = apps.find(({name}) => name === 'Thryv Marketplace')
    let childSubscriptions = []
    for (let center of centers) {
      if (center?.name.includes('Command Center')) {
        continue
      } else {
        const formattedCenter = formatCenters(centers, ({name}) =>
          name.includes(center?.name),
        )
        childSubscriptions.push(formattedCenter[0])
      }
    }

    childSubscriptions.push({
      code: marketplace.code,
      quantity: 1,
      addons: apps
        .filter(({name, code}) => {
          return (
            name !== 'Thryv Marketplace' &&
            (name !== 'SEO Keywords') & (name !== 'SEO PowerBoost')
          )
        })
        .map(({code, quantity = 1}) => {
          return {
            code,
            quantity,
          }
        }),
    })

    const oneTimeFees = oneTimes.map(({code, quantity = 1}) => {
      return {
        code,
        quantity,
      }
    })

    // Hanlde new Alpha SEO
    const newAlphaSEO = centers.find(({name}) => name === 'SEO')
    const keyword = apps.find(({name}) => name === 'SEO Keywords')
    const powerboost = apps.find(({name}) => name === 'SEO PowerBoost')
    if (newAlphaSEO && (keyword || powerboost)) {
      for (let subscription of childSubscriptions) {
        if (subscription.code === 'seobase-5-thryv-m2m') {
          if (keyword)
            subscription.addons.push({
              code: keyword.code,
              quantity: keyword.quantity,
            })
          if (powerboost)
            subscription.addons.push({
              code: powerboost.code,
              quantity: powerboost.quantity,
            })
        }
      }
    }

    const data = {
      firstName: order.accountInformation.firstName,
      lastName: order.accountInformation.lastName,
      email: order.accountInformation.email,
      company: order.accountInformation.company,
      currency: CC_CURRENCIES[order.selectedCountry.value],
      industryId: order.template,
      businessAddress: {
        street: order.accountInformation.address,
        city: order.accountInformation.city,
        state: order.accountInformation.state,
        postalCode: order.accountInformation.postCode,
        country: order.selectedCountry.isoValue,
        phone: order.accountInformation.companyPhone,
      },
      contactPhone: contactInfo,
      thryvId: order.CCAccountInfo.thryvId,
      flow: 'new',
      sendLinkVia: contactMethod,
      parentSubscriptions,
      childSubscriptions,
      oneTimeFees,
      isCohort,
    }
    if (cohortEaid) {
      data.cohortEaid = cohortEaid
    }
    if (
      order.selectedCountry.isoValue === 'KY' ||
      order.selectedCountry.isoValue === 'BB'
    ) {
      data.currency = 'USD'
    }
    if (kickoffApptId) {
      data.onboardingApptId = kickoffApptId
    }
    if (creativeApptId) {
      data.creativeApptId = creativeApptId
    }
    if (order.accountInformation.xpCode?.length > 0) {
      data.xpCode = order.accountInformation.xpCode
    }
    if (
      order.accountInformation.differentSalesRep?.value === true ||
      order.accountInformation.differentSalesRep?.value === 'true'
    ) {
      if (order.accountInformation.behalf) {
        data.behalfRepId = order.accountInformation.behalf
      }
      if (order.accountInformation.behalfFirst) {
        data.behalfFirstName = order.accountInformation.behalfFirst
      }
      if (order.accountInformation.behalfLast) {
        data.behalfLastName = order.accountInformation.behalfLast
      }
      if (order.accountInformation.behalfEmail) {
        data.behalfEmail = order.accountInformation.behalfEmail
      }
    }
    if (order.accountInformation.overrideRep) {
      if (order.accountInformation.overrideRepCode) {
        data.overrideRepId = order.accountInformation.overrideRepCode
      }
      if (order.accountInformation.overrideRepFirst) {
        data.overrideRepFirst = order.accountInformation.overrideRepFirst
      }
      if (order.accountInformation.overrideRepLast) {
        data.overrideRepLast = order.accountInformation.overrideRepLast
      }
      if (order.accountInformation.overrideRepEmail) {
        data.overrideRepEmail = order.accountInformation.overrideRepEmail
      }
    }
    if (
      ['AU', 'NZ'].includes(order.selectedCountry.isoValue) &&
      order.accountInformation.abn
    ) {
      data.abn = order.accountInformation.abn.replaceAll(' ', '')
    }
    if (data?.abn === '') {
      delete data.abn
    }
    if (order.onBoardingProgramData?.csmExtraInfo) {
      data.orderNotes = order.onBoardingProgramData.csmExtraInfo
    }
    if (thryvLeadsId) {
      data.thryvLeadsId = thryvLeadsId
    }
    if (alphaSeo) {
      data.seoProposalId = alphaSeo.alphaProposalId
      data.seoDomain = alphaSeo.alphaDomain
      // data.seoCategories = alphaSeo.alphaCategories
      // data.seoGeos = alphaSeo.alphaGeos
    }
    if (seoProposal) {
      data.seoProposalId = seoProposal.seoProposalId
      data.seoDomain = seoProposal.seoDomain
    }
    if (sensisDomain) {
      data.sensisDomain = sensisDomain
    }
    if (selectedPaymentMethod) {
      data.isInvoiceBilled = selectedPaymentMethod === 'Invoice'
    }
    if (mcDomain) {
      data.mcDomain = mcDomain
    }
    if (demoLeadId) {
      data.demoLeadId = demoLeadId
    }
    if (orderCouponCodes.find(x => x.code === 'transition75')) {
      const couponCodes = ['25month1', '25month2', '25month3']
      const {code} = order.selectedCentersAndApps.centers.find(({name}) =>
        CC_PLANS.some(plan => name.toLowerCase().includes(plan)),
      )
      const createUrl = couponCode =>
        `orders/coupon/validate?couponCode=${couponCode}&country=${order.selectedCountry.isoValue}&planCode=${code}`
      const [transitionCoupon1, transitionCoupon2, transitionCoupon3] =
        await Promise.all(
          couponCodes.map(async code => {
            const url = createUrl(code)
            return await client(url)
          }),
        )
      const transitionCouponIndex = orderCouponCodes.findIndex(
        addonInfo => addonInfo.code === 'transition75',
      )
      const couponsCopy = [...orderCouponCodes]
      captureOrderDiscounts(couponsCopy)

      couponsCopy.splice(transitionCouponIndex, 1)
      couponsCopy.push(transitionCoupon1, transitionCoupon2, transitionCoupon3)

      const COUPON_CODES = couponsCopy
        .filter(coupon => {
          return coupon.isValid
        })
        .map(coupon => {
          return coupon.code
        })
      if (COUPON_CODES.length > 0) {
        data.coupons = COUPON_CODES
      }
    } else {
      const ALL_COUPON_CODES = orderCouponCodes
        .filter(coupon => {
          return coupon.isValid
        })
        .map(coupon => {
          return coupon.code
        })
      if (ALL_COUPON_CODES.length > 0) {
        data.coupons = ALL_COUPON_CODES
      } else {
        data.coupons = []
      }
    }

    try {
      const result = await run(client('cc/order/send-link', {data}))
      if (result.message === 'Payment link sent') {
        toast.success(result.message)
        updateOrder5Status('send-link', 'Submitted', result)
      } else {
        toast.error(result.message)
      }
      if (result.paymentUuid) {
        return result
      }
    } catch (error) {
      setProcessing(false)
      if (error.status !== 400) {
        toast.error(`Error sending link: ${error.message}`)
        throw error
      }
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        toast.error(`Error sending link: ${error.message}`)
      }
    }
  }

  const resendPaymentLink = async current => {
    const data = {
      orderId: current.orderId.toString(),
      country: order.selectedCountry.isoValue,
      method: current.method,
      email: current.email,
    }
    if (order?.selectedCentersAndApps) {
      data.contactPhone = current.mobile
    } else {
      data.mobile = current.mobile
    }
    try {
      const {newUuid, shortenedUrl} = await run(
        client(
          !order?.selectedCentersAndApps
            ? 'orders/process/resend-link'
            : 'cc/order/resend-link',
          {data, method: 'PATCH'},
        ),
      )
      if (newUuid) {
        toast.success(`${current.method} resent`)
        return {updatedUuid: newUuid, shortenedUrl}
      } else {
        toast.error('error resending link')
      }
    } catch (error) {
      setProcessing(false)
      toast.error('Error resending link')
    }
  }

  const checkClientEmailStatus = async current => {
    const data = {
      email: current.email,
    }

    try {
      const result = await run(
        client(`orders/email-status/${data.email}`, {method: 'GET'}),
      )

      if (result.haswarningorError) {
        toast.error(result.error_message)
      }
      if (
        result.haswarningorError === false &&
        result.show_error_msg === false
      ) {
        if (result.show_send_sms_btn) {
          setShwoSmsOption(true)
        }
      } else {
        setShwoSmsOption(false)
      }
    } catch (err) {
      toast.error(err.error)
    }
  }

  const captureOrder5NewUpgrade = async (context, orderFlow, contextData) => {
    try {
      console.log('captureOrder5NewUpgrade() - context', context)
      console.log('captureOrder5NewUpgrade() - orderFlow', orderFlow)
      console.log('captureOrder5NewUpgrade() - contextData', contextData)
      let tempOrderData5CU = {}
      if (context === 'catalog-init-newAccOrder') {
        tempOrderData5CU = contextData
      } else {
        tempOrderData5CU = orderData5CU
      }
      const data = {
        order_status: 'Pending',
        order_version: '5.0',
        order_flow: 'New',
        order_submitted_date: new Date(),
        order_submitted_by: 'null',
        order_completed_date: null,
        industry_id: tempOrderData5CU.order.industryCategoryId.toString(),
        industry_description:
          tempOrderData5CU.order.industryCategoryDescription,
        country_code: tempOrderData5CU.order.selectedCountry.isoValue,
        account_email: tempOrderData5CU.accountInfo.email,
        contact_first_name: tempOrderData5CU.accountInfo.firstName,
        contact_last_name: tempOrderData5CU.accountInfo.lastName,
        contact_phone: tempOrderData5CU.accountInfo.businessAddress.phone
          ? tempOrderData5CU.accountInfo.businessAddress.phone.toString()
          : '1234', // check
        contact_mobile: tempOrderData5CU.accountInfo.businessAddress.phone
          ? tempOrderData5CU.accountInfo.businessAddress.phone.toString()
          : '12345', // check
        contact_email: tempOrderData5CU.accountInfo.email,
        company_name: tempOrderData5CU.accountInfo.company,
        company_address1: tempOrderData5CU.accountInfo.businessAddress.street1,
        company_address2: tempOrderData5CU.accountInfo.businessAddress.street2,
        company_city: tempOrderData5CU.accountInfo.businessAddress.city,
        company_state: tempOrderData5CU.accountInfo.businessAddress.region,
        company_zipcode:
          tempOrderData5CU.accountInfo.businessAddress.postalCode,
        company_phone: tempOrderData5CU.accountInfo.businessAddress.phone
          ? tempOrderData5CU.accountInfo.businessAddress.phone.toString()
          : '12334',
        sales_rep_id: tempOrderData5CU.accountInfo.salesRepCode
          ? tempOrderData5CU.accountInfo.salesRepCode
          : null, //need to check
        on_behalf_of: tempOrderData5CU.accountInfo.behalfEmail
          ? tempOrderData5CU.accountInfo.behalfEmail
          : null,
        xp_code: tempOrderData5CU.accountInfo.xpCode
          ? tempOrderData5CU.accountInfo.xpCode
          : null,
        abn: tempOrderData5CU.accountInfo.abn
          ? tempOrderData5CU.accountInfo.abn
          : null, //need to check,
        thryv_id: tempOrderData5CU.order.CCAccountInfo.thryvId,
        account_code: tempOrderData5CU.order.CCAccountInfo.ccId
          ? tempOrderData5CU.order.CCAccountInfo.ccId
          : 'null',
        eaid: tempOrderData5CU.accountInfo.eaid
          ? tempOrderData5CU.accountInfo.eaid
          : 'null',
        is_invoice_billed: tempOrderData5CU.accountInfo.allowInvoice ? 1 : 0,
        is_cohort: null, //need to check,
        demo_lead_id: null,
        no_service: null, //need to check
        onboard_appt_id: order5OnboardingApptId,
        creative_appt_id: order5CreativeApptId,
        update_to_autopay: 1, //check
        on_boarding_fee: 0, //need to check
        purchase_intent_1: null, //need to check,
        purchase_intent_2: null, //need to check,
        purchase_intent_3: null, //need to check,
        sensis_site: null, //need to check,
        lines: [],
        discounts: [],
        reseller: [],
      }

      if (context === 'catalog-init-newAccOrder') {
        // const discountsData = []
        data.order_status = 'Completed'
        data.order_completed_date = new Date()
        let lineCounter = 1
        const lines = []

        const subscriptions = [
          ...contextData.subscriptions.parentSubscriptions,
          ...contextData.subscriptions.childSubscriptions,
        ]
        subscriptions.forEach(subs => {
          const pCat = subs?.name?.includes('Center') ? 'Center' : 'App'
          const line = {
            line_number: lineCounter,
            parent_line_number: null,
            plansv5_id: 'vipmzc731i88',
            action_code: 'New',
            order_line_status: 'completed',
            saas_product_category: pCat,
            saas_product_type: 'main',
            saas_product_code: subs.code,
            saas_instance_id: null,
            recurly_subscription_id: subs.id,
            saas_product_price: subs.total,
            one_time_fee: null,
            start_date: new Date(),
            end_date: new Date(),
          }
          let p_lc = lineCounter
          lineCounter = lineCounter + 1
          lines.push(line)
          if (subs.addons && subs.addons.length > 0) {
            subs.addons.forEach(addon => {
              const line = {
                line_number: lineCounter,
                parent_line_number: p_lc,
                plansv5_id: null,
                action_code: 'New',
                order_line_status: 'completed',
                saas_product_category: pCat,
                saas_product_type: pCat === 'Center' ? 'entitlement' : 'addon',
                saas_product_code: addon.code,
                saas_instance_id: null,
                recurly_subscription_id: addon.id,
                saas_product_price: addon.subtotal,
                one_time_fee: null,
                start_date: new Date(),
                end_date: new Date(),
              }
              lineCounter = lineCounter + 1
              lines.push(line)
            })
          }
        })
        data.lines = lines
        setOrder5SelectedLines(lines)
      } else if (context === 'catalog-continue-summary') {
        data.order_status = 'Pending'
        data.lines = contextData
        data.discounts = order5LinesDiscounts
        setOrder5SelectedLines(contextData)
      } else if (context === 'capture-appointment') {
        console.log(
          'order5SelectedLines - upgrade - capture-appointment',
          order5SelectedLines,
        )
        console.log(
          'order5LinesDiscounts - upgrade - capture-appointment',
          order5LinesDiscounts,
        )
        if (contextData.appointment_type === 'creative') {
          data.creative_appt_id = contextData.apptId
        } else if (contextData.appointment_type === 'onboarding') {
          data.onboard_appt_id = contextData.apptId
        }
        data.lines = order5SelectedLines
        data.discounts = order5LinesDiscounts
      } else {
        console.log(
          'order5SelectedLines - upgrade - other context',
          order5SelectedLines,
        )
        console.log(
          'order5LinesDiscounts - upgrade - other context',
          order5LinesDiscounts,
        )
        data.lines = order5SelectedLines
        data.discounts = order5LinesDiscounts
      }

      if (isReseller) {
        const resellerData = getResellerData(data)
        console.log('resellerData', resellerData)
        if (resellerData) {
          data.reseller.push(resellerData)
        }
      }

      if (!order5NUId) {
        console.log('createOrderData - newAccorder', data)
        const result = await run(
          client('ordercapture/services/createOrder', {data, method: 'POST'}),
        )
        console.log('order created - newAccorder', result)
        if (context === 'catalog-init-newAccOrder') {
          setOrder5NUId(null)
          setIsNewAccountOrderCreated(true)
        } else {
          setOrder5NUId(result.order_id)
        }
      } else {
        data.order_id = order5NUId
        console.log('UpdateOrderData - newAccorder', data)
        const result = await run(
          client('ordercapture/services/updateOrder', {data, method: 'POST'}),
        )
        console.log('order updated - newAccorder', result)
      }
    } catch (error) {
      if (error.status !== 400) {
        console.log(`Error creating/updating newAccorder: ${error.message}`)
      }
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        console.log(`Error creating/updating newAccorder: ${error.message}`)
      }
    }
  }

  const getResellerData = data => {
    if (data.lines && data.lines.length > 0) {
      const nonCcProducts = [
        'cc_basicpls-5-thryv-m2m',
        'appmarket-5-thryv-m2m',
        'addon-5-signatures_usage',
        'app-websitebuilder',
      ]
      const isnonCCPrduct = data.lines.every(line =>
        nonCcProducts.some(prdct => prdct === line.saas_product_code),
      )
      console.log('isnonCCPrduct', isnonCCPrduct)
      if (!isnonCCPrduct) {
        const resellerData = {
          account_code: orderData5CU.recurlyAccount.code
            ? orderData5CU.recurlyAccount.code
            : null,
          account_id: orderData5CU.recurlyAccount.billingInfo?.accountId
            ? orderData5CU.recurlyAccount.billingInfo?.accountId
            : orderData5CU.recurlyAccount.id
            ? orderData5CU.recurlyAccount.id
            : null,
          no_service: null,
          original_selling_coupon_code: null,
          subscription_id: null,
          thryv_id: orderData5CU.order.CCAccountInfo.thryvId,
          uuid: null,
        }
        return resellerData
      } else {
        return null
      }
      // return null
    }
  }

  const updateOrder5Status = async (context, orderFlow, contextData) => {
    console.log('updateOrder5Status() - context', context)
    console.log('updateOrder5Status() - orderFlow', orderFlow)
    console.log('updateOrder5Status() - contextData', contextData)
    try {
      const statusData = {
        order_id: order5NUId,
        order_status: 'Pending',
      }
      if (context === 'send-link') {
        statusData.order_status = 'Submitted'
      } else if (context === 'cancel-order') {
        statusData.order_status = 'Cancelled'
      }
      const result = await run(
        client('ordercapture/services/updateOrderStatus', {
          data: statusData,
          method: 'PUT',
        }),
      )
      console.log('order updated - NewAcc - status', result)
    } catch (error) {
      if (error.status !== 400) {
        console.log(`Error updating order status: ${error.message}`)
        // throw error
      }
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        console.log(`Error updating order status: ${error.message}`)
      }
    }
  }

  const updateApptOnExpire = async (appointment_type, apptId) => {
    const appointmentObj = {
      order_id: order5NUId,
      schedule_status: 'Expired',
      appointment_type: appointment_type,
      appt_id: apptId,
    }
    try {
      // const data = appointmentObj
      const result = await run(
        client('ordercapture/services/updateScheduledAppointment', {
          data: appointmentObj,
          method: 'PUT',
        }),
      )
      if (result.success) {
        if (appointment_type === 'creative') {
          setOrder5CreativeApptId(null)
        } else if (appointment_type === 'onboarding') {
          setOrder5OnboardingApptId(null)
        }
        const contextData = {
          appointment_type: appointment_type,
          apptId: null,
        }
        captureOrder5NewUpgrade(
          'capture-appointment',
          'new-upgrade',
          contextData,
        )
      } else {
        console.log(result.message)
      }
    } catch (error) {
      if (error.status !== 400) {
        console.log('error in cancelling Appointment', error)
        // throw error
      }
      if (error.errors) {
        console.log('error in cancelling Appointment', error)
        // throw error
      }
    }
  }

  const captureOrderDiscounts = async discountData => {
    const allDiscount = []
    let lineCounter = 1
    for (let data of discountData) {
      console.log('disData', data)
      // const today = new Date()
      const dataObj = {
        order_id: order5NUId,
        line_number: lineCounter,
        discount_type: data.type,
        coupon_code: data.code,
        discount_percentage: data.discountPercent,
        discount_amount: data.discountAmount,
        number_of_months: data.durationAmount,
        start_date: new Date(),
        end_date: datePlusDuration(
          new Date(),
          data.durationAmount,
          data.durationUnit,
        ),
        created_date: null,
        create_oprid: null,
        updated_date: null,
        update_oprid: null,
      }
      allDiscount.push(dataObj)
      lineCounter = lineCounter + 1
    }
    setOrder5LinesDiscounts(allDiscount)
    // captureOrder5NewUpgrade('preview-payment', 'new-upgrade', allDiscount)
  }

  function datePlusDuration(date, duration, unit) {
    const newDate = new Date(date)
    if (unit === 'month') newDate.setMonth(newDate.getMonth() + duration)
    if (unit === 'day') newDate.setDate(newDate.getDate() + duration)
    if (unit === 'year') newDate.setFullYear(newDate.getFullYear() + duration)
    return newDate
  }

  return (
    <Container frontendSideNav={frontendSideNav}>
      <MainContainer>
        <WizardContainer>
          <StepsContainer>
            {currentStep === 1 && (
              <CountrySelection nextStep={nextStep} prevStep={prevStep} />
            )}
            {currentStep === 2 && (
              <IndustryCategorySelection
                nextStep={nextStep}
                prevStep={prevStep}
                selectedCountry={order.selectedCountry.isoValue}
              />
            )}
            {currentStep === 3 && (
              <IndustrySelection
                nextStep={nextStep}
                prevStep={prevStep}
                selectedCountry={order.selectedCountry}
                selectedIndustry={order.industryCategoryId}
                industryCategoryType={order.industryCategoryType}
              />
            )}
            {currentStep === 4 && (
              <AccountInformation
                nextStep={nextStep}
                prevStep={prevStep}
                selectedCountry={order.selectedCountry.isoValue}
                billingPreference={order.billingPreference}
                selectedPlan={order.selectedPlan}
                industryCategoryType={order.industryCategoryType}
                industryTemplate={order.template}
                order={order}
                currentStep={currentStep}
                showBackButton={false}
                isEaidFound={isEaidFound}
                setIsEaidFound={setIsEaidFound}
              />
            )}
            {currentStep === 5 && (
              <Catalog
                nextStep={nextStep}
                prevStep={prevStep}
                selectedCountry={order.selectedCountry.isoValue}
                CCAccountInfo={order.CCAccountInfo}
                industryTemplate={order.template}
                prevCentersAndApps={order?.selectedCentersAndApps}
                prevCode={order?.code}
                ccCouponInfo={ccCouponInfo}
                setCcCouponInfo={setCcCouponInfo}
                setOrderCouponCodes={setOrderCouponCodes}
                orderCouponCodes={orderCouponCodes}
                setIncentiveCoupon={setIncentiveCoupon}
                all5PlanPrices={all5PlanPrices}
                setIsEaidFound={setIsEaidFound}
                isEaidFound={isEaidFound}
                cohortEaid={cohortEaid}
                setCohortEaid={setCohortEaid}
                resetAutoCoupons={resetAutoCoupons}
                accountInfo={order.accountInformation}
                setAccountInfo={setAccountInfo}
                updateAddressInfo={updateAddressInfo}
                setAccountSubscriptionData={setAccountSubscriptionData}
                accountSubscriptionData={accountSubscriptionData}
                order={order}
                setAllowInvoice={setAllowInvoice}
                allowInvoice={allowInvoice}
                isCohort={isCohort}
                setIsCohort={setIsCohort}
                isNewAccountOrderCreated={isNewAccountOrderCreated}
                orderData5CU={orderData5CU}
                setOrderData5CU={setOrderData5CU}
                captureOrder5NewUpgrade={captureOrder5NewUpgrade}
              />
            )}
            {currentStep === 6 && (
              <OnboardingProgram
                nextStep={nextStep}
                prevStep={prevStep}
                selectedPlan={order.selectedPlan}
                billingPreference={order.billingPreference}
                accountInformation={order.accountInformation}
                industryCategoryType={order.industryCategoryType}
                selectedCountry={order.selectedCountry}
                selectedAddons={order.selectedAddons}
                setCreativeApptId={setCreativeApptId}
                setKickoffApptId={setKickoffApptId}
                kickoffApptId={kickoffApptId}
                creativeApptId={creativeApptId}
                onboardingCallDate={onboardingCallDate}
                setOnboardingCallDate={setOnboardingCallDate}
                creativeCallDate={creativeCallDate}
                setCreativeCallDate={setCreativeCallDate}
                allSelectedPurchaseIntent={allSelectedPurchaseIntent}
                setAllSelectedPurchaseIntent={setAllSelectedPurchaseIntent}
                currentStep={currentStep}
                setCartProducts={setCartProducts}
                cartProducts={cartProducts}
                selectedIndustryId={order.industryCategoryId}
                selectedCentersAndApps={order?.selectedCentersAndApps}
                country={order.selectedCountry.isoValue}
                orderCouponCodes={orderCouponCodes}
                setOrderCouponCodes={setOrderCouponCodes}
                incentiveCoupon={incentiveCoupon}
                setIncentiveCoupon={setIncentiveCoupon}
                upgradeAccountInfo={accountInfo}
                autoCouponInfo={autoCouponInfo}
                ccCouponInfo={ccCouponInfo}
                isCohort={isCohort}
                order5NUId={order5NUId}
                isCancelOrder5={true}
                cancelOrder5={updateOrder5Status}
                // setOrder5AllDiscounts={setOrder5AllDiscounts}
                captureOrderNewUpgrade={captureOrder5NewUpgrade}
                setOrder5CreativeApptId={setOrder5CreativeApptId}
                setOrder5OnboardingApptId={setOrder5OnboardingApptId}
              />
            )}
            {currentStep === 7 && (
              <PaymentDetails
                resendPaymentLink={resendPaymentLink}
                checkClientEmailStatus={checkClientEmailStatus}
                showSmsOption={showSmsOption}
                setShwoSmsOption={setShwoSmsOption}
                nextStep={nextStep}
                prevStep={prevStep}
                selectedAddons={order.selectedAddons}
                selectedCountry={order.selectedCountry.value}
                accountInformation={order.accountInformation}
                planCode={order.package}
                countryIsoValue={order.selectedCountry.isoValue}
                creativeApptId={creativeApptId}
                kickoffApptId={kickoffApptId}
                setKickoffApptId={setKickoffApptId}
                setCreativeApptId={setCreativeApptId}
                setOrderCouponCodes={setOrderCouponCodes}
                orderCouponCodes={orderCouponCodes}
                setCartProducts={setCartProducts}
                accountInfo={accountInfo}
                cartProducts={cartProducts}
                currentStep={currentStep}
                processing={processing}
                setProcessing={setProcessing}
                setCreativeCallDate={setCreativeCallDate}
                setOnboardingCallDate={setOnboardingCallDate}
                selectedPlan={order.selectedPlan}
                clientEmail={order.accountInformation.email}
                selectedCentersAndApps={order?.selectedCentersAndApps}
                sendCCPaymentLink={sendCCPaymentLink}
                ccCouponInfo={ccCouponInfo}
                onboardingCallDate={onboardingCallDate}
                creativeCallDate={creativeCallDate}
                incentiveCoupon={incentiveCoupon}
                isSEOIncluded={isSEOIncluded}
                autoCouponInfo={autoCouponInfo}
                allowInvoice={allowInvoice}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                selectedPaymentMethod={selectedPaymentMethod}
                isCohort={isCohort}
                cohortEaid={cohortEaid}
                orderData5CU={orderData5CU}
                order5NUId={order5NUId}
                isCancelOrder5={true}
                cancelOrder5={updateOrder5Status}
                updateApptOnExpire={updateApptOnExpire}
              />
            )}
            {currentStep === 8 && (
              <Commitment
                prevStep={prevStep}
                clientInitials={clientInitials}
                updateInitials={updateInitials}
                purchaseIntents={allSelectedPurchaseIntent}
                onboardingCallDate={onboardingCallDate}
                creativeCallDate={creativeCallDate}
                clientEmail={order.accountInformation.email}
              />
            )}
          </StepsContainer>
        </WizardContainer>
      </MainContainer>
    </Container>
  )
}

export {NewOrder}
